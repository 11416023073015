/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createSMSSend = /* GraphQL */ `mutation CreateSMSSend(
  $input: CreateSMSSendInput!
  $condition: ModelSMSSendConditionInput
) {
  createSMSSend(input: $input, condition: $condition) {
    id
    sitesID
    sendNo
    schdAt
    purpose
    cntSMS
    inputType
    sendType
    sendStatus
    smsSubject
    smsContent
    costCenter
    isDemo
    isAd
    receiveNumbers
    cdrId
    reqId
    confirmMemberIdx
    confirmMemberId
    confirmMemberName
    confirmAt
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSMSSendMutationVariables,
  APITypes.CreateSMSSendMutation
>;
export const updateSMSSend = /* GraphQL */ `mutation UpdateSMSSend(
  $input: UpdateSMSSendInput!
  $condition: ModelSMSSendConditionInput
) {
  updateSMSSend(input: $input, condition: $condition) {
    id
    sitesID
    sendNo
    schdAt
    purpose
    cntSMS
    inputType
    sendType
    sendStatus
    smsSubject
    smsContent
    costCenter
    isDemo
    isAd
    receiveNumbers
    cdrId
    reqId
    confirmMemberIdx
    confirmMemberId
    confirmMemberName
    confirmAt
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSMSSendMutationVariables,
  APITypes.UpdateSMSSendMutation
>;
export const deleteSMSSend = /* GraphQL */ `mutation DeleteSMSSend(
  $input: DeleteSMSSendInput!
  $condition: ModelSMSSendConditionInput
) {
  deleteSMSSend(input: $input, condition: $condition) {
    id
    sitesID
    sendNo
    schdAt
    purpose
    cntSMS
    inputType
    sendType
    sendStatus
    smsSubject
    smsContent
    costCenter
    isDemo
    isAd
    receiveNumbers
    cdrId
    reqId
    confirmMemberIdx
    confirmMemberId
    confirmMemberName
    confirmAt
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSMSSendMutationVariables,
  APITypes.DeleteSMSSendMutation
>;
export const createSMSTemplateMsg = /* GraphQL */ `mutation CreateSMSTemplateMsg(
  $input: CreateSMSTemplateMsgInput!
  $condition: ModelSMSTemplateMsgConditionInput
) {
  createSMSTemplateMsg(input: $input, condition: $condition) {
    id
    sitesID
    isEvent
    sendCategory
    sendCategoryDetail
    isBizTalk
    bizTalkCode
    description
    smsSubject
    smsType
    smsContent
    extraInfo
    adMessage
    highlightType
    highLightTitle
    highLightComment
    buttons {
      type
      name
      url_mobile
      url_pc
      scheme_android
      scheme_ios
      __typename
    }
    sendCondition
    sendTime
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSMSTemplateMsgMutationVariables,
  APITypes.CreateSMSTemplateMsgMutation
>;
export const updateSMSTemplateMsg = /* GraphQL */ `mutation UpdateSMSTemplateMsg(
  $input: UpdateSMSTemplateMsgInput!
  $condition: ModelSMSTemplateMsgConditionInput
) {
  updateSMSTemplateMsg(input: $input, condition: $condition) {
    id
    sitesID
    isEvent
    sendCategory
    sendCategoryDetail
    isBizTalk
    bizTalkCode
    description
    smsSubject
    smsType
    smsContent
    extraInfo
    adMessage
    highlightType
    highLightTitle
    highLightComment
    buttons {
      type
      name
      url_mobile
      url_pc
      scheme_android
      scheme_ios
      __typename
    }
    sendCondition
    sendTime
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSMSTemplateMsgMutationVariables,
  APITypes.UpdateSMSTemplateMsgMutation
>;
export const deleteSMSTemplateMsg = /* GraphQL */ `mutation DeleteSMSTemplateMsg(
  $input: DeleteSMSTemplateMsgInput!
  $condition: ModelSMSTemplateMsgConditionInput
) {
  deleteSMSTemplateMsg(input: $input, condition: $condition) {
    id
    sitesID
    isEvent
    sendCategory
    sendCategoryDetail
    isBizTalk
    bizTalkCode
    description
    smsSubject
    smsType
    smsContent
    extraInfo
    adMessage
    highlightType
    highLightTitle
    highLightComment
    buttons {
      type
      name
      url_mobile
      url_pc
      scheme_android
      scheme_ios
      __typename
    }
    sendCondition
    sendTime
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSMSTemplateMsgMutationVariables,
  APITypes.DeleteSMSTemplateMsgMutation
>;
export const createDailyQuizGroup = /* GraphQL */ `mutation CreateDailyQuizGroup(
  $input: CreateDailyQuizGroupInput!
  $condition: ModelDailyQuizGroupConditionInput
) {
  createDailyQuizGroup(input: $input, condition: $condition) {
    id
    title
    DailyQuizBases {
      nextToken
      startedAt
      __typename
    }
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDailyQuizGroupMutationVariables,
  APITypes.CreateDailyQuizGroupMutation
>;
export const updateDailyQuizGroup = /* GraphQL */ `mutation UpdateDailyQuizGroup(
  $input: UpdateDailyQuizGroupInput!
  $condition: ModelDailyQuizGroupConditionInput
) {
  updateDailyQuizGroup(input: $input, condition: $condition) {
    id
    title
    DailyQuizBases {
      nextToken
      startedAt
      __typename
    }
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDailyQuizGroupMutationVariables,
  APITypes.UpdateDailyQuizGroupMutation
>;
export const deleteDailyQuizGroup = /* GraphQL */ `mutation DeleteDailyQuizGroup(
  $input: DeleteDailyQuizGroupInput!
  $condition: ModelDailyQuizGroupConditionInput
) {
  deleteDailyQuizGroup(input: $input, condition: $condition) {
    id
    title
    DailyQuizBases {
      nextToken
      startedAt
      __typename
    }
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDailyQuizGroupMutationVariables,
  APITypes.DeleteDailyQuizGroupMutation
>;
export const createDailyQuizBase = /* GraphQL */ `mutation CreateDailyQuizBase(
  $input: CreateDailyQuizBaseInput!
  $condition: ModelDailyQuizBaseConditionInput
) {
  createDailyQuizBase(input: $input, condition: $condition) {
    id
    title
    quizDate
    quizBeginDate
    quizEndDate
    dailyquizgroupID
    correctAnswerRate
    participants
    DailyQuizQuestions {
      nextToken
      startedAt
      __typename
    }
    DailyQuizSentences {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDailyQuizBaseMutationVariables,
  APITypes.CreateDailyQuizBaseMutation
>;
export const updateDailyQuizBase = /* GraphQL */ `mutation UpdateDailyQuizBase(
  $input: UpdateDailyQuizBaseInput!
  $condition: ModelDailyQuizBaseConditionInput
) {
  updateDailyQuizBase(input: $input, condition: $condition) {
    id
    title
    quizDate
    quizBeginDate
    quizEndDate
    dailyquizgroupID
    correctAnswerRate
    participants
    DailyQuizQuestions {
      nextToken
      startedAt
      __typename
    }
    DailyQuizSentences {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDailyQuizBaseMutationVariables,
  APITypes.UpdateDailyQuizBaseMutation
>;
export const deleteDailyQuizBase = /* GraphQL */ `mutation DeleteDailyQuizBase(
  $input: DeleteDailyQuizBaseInput!
  $condition: ModelDailyQuizBaseConditionInput
) {
  deleteDailyQuizBase(input: $input, condition: $condition) {
    id
    title
    quizDate
    quizBeginDate
    quizEndDate
    dailyquizgroupID
    correctAnswerRate
    participants
    DailyQuizQuestions {
      nextToken
      startedAt
      __typename
    }
    DailyQuizSentences {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDailyQuizBaseMutationVariables,
  APITypes.DeleteDailyQuizBaseMutation
>;
export const createDailyQuizSentence = /* GraphQL */ `mutation CreateDailyQuizSentence(
  $input: CreateDailyQuizSentenceInput!
  $condition: ModelDailyQuizSentenceConditionInput
) {
  createDailyQuizSentence(input: $input, condition: $condition) {
    id
    sentenceImage
    sentenceText
    dailyquizbaseID
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDailyQuizSentenceMutationVariables,
  APITypes.CreateDailyQuizSentenceMutation
>;
export const updateDailyQuizSentence = /* GraphQL */ `mutation UpdateDailyQuizSentence(
  $input: UpdateDailyQuizSentenceInput!
  $condition: ModelDailyQuizSentenceConditionInput
) {
  updateDailyQuizSentence(input: $input, condition: $condition) {
    id
    sentenceImage
    sentenceText
    dailyquizbaseID
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDailyQuizSentenceMutationVariables,
  APITypes.UpdateDailyQuizSentenceMutation
>;
export const deleteDailyQuizSentence = /* GraphQL */ `mutation DeleteDailyQuizSentence(
  $input: DeleteDailyQuizSentenceInput!
  $condition: ModelDailyQuizSentenceConditionInput
) {
  deleteDailyQuizSentence(input: $input, condition: $condition) {
    id
    sentenceImage
    sentenceText
    dailyquizbaseID
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDailyQuizSentenceMutationVariables,
  APITypes.DeleteDailyQuizSentenceMutation
>;
export const createDailyQuizQuestion = /* GraphQL */ `mutation CreateDailyQuizQuestion(
  $input: CreateDailyQuizQuestionInput!
  $condition: ModelDailyQuizQuestionConditionInput
) {
  createDailyQuizQuestion(input: $input, condition: $condition) {
    id
    questionNumber
    questionContent
    questions
    answer
    commentary
    relatedTerms
    correctAnswerRate
    DailyQuizQuestionAnswers {
      nextToken
      startedAt
      __typename
    }
    dailyquizbaseID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDailyQuizQuestionMutationVariables,
  APITypes.CreateDailyQuizQuestionMutation
>;
export const updateDailyQuizQuestion = /* GraphQL */ `mutation UpdateDailyQuizQuestion(
  $input: UpdateDailyQuizQuestionInput!
  $condition: ModelDailyQuizQuestionConditionInput
) {
  updateDailyQuizQuestion(input: $input, condition: $condition) {
    id
    questionNumber
    questionContent
    questions
    answer
    commentary
    relatedTerms
    correctAnswerRate
    DailyQuizQuestionAnswers {
      nextToken
      startedAt
      __typename
    }
    dailyquizbaseID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDailyQuizQuestionMutationVariables,
  APITypes.UpdateDailyQuizQuestionMutation
>;
export const deleteDailyQuizQuestion = /* GraphQL */ `mutation DeleteDailyQuizQuestion(
  $input: DeleteDailyQuizQuestionInput!
  $condition: ModelDailyQuizQuestionConditionInput
) {
  deleteDailyQuizQuestion(input: $input, condition: $condition) {
    id
    questionNumber
    questionContent
    questions
    answer
    commentary
    relatedTerms
    correctAnswerRate
    DailyQuizQuestionAnswers {
      nextToken
      startedAt
      __typename
    }
    dailyquizbaseID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDailyQuizQuestionMutationVariables,
  APITypes.DeleteDailyQuizQuestionMutation
>;
export const createDailyQuizQuestionAnswer = /* GraphQL */ `mutation CreateDailyQuizQuestionAnswer(
  $input: CreateDailyQuizQuestionAnswerInput!
  $condition: ModelDailyQuizQuestionAnswerConditionInput
) {
  createDailyQuizQuestionAnswer(input: $input, condition: $condition) {
    id
    questionNumber
    userAnswer
    isCorrect
    dailyquizquestionID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDailyQuizQuestionAnswerMutationVariables,
  APITypes.CreateDailyQuizQuestionAnswerMutation
>;
export const updateDailyQuizQuestionAnswer = /* GraphQL */ `mutation UpdateDailyQuizQuestionAnswer(
  $input: UpdateDailyQuizQuestionAnswerInput!
  $condition: ModelDailyQuizQuestionAnswerConditionInput
) {
  updateDailyQuizQuestionAnswer(input: $input, condition: $condition) {
    id
    questionNumber
    userAnswer
    isCorrect
    dailyquizquestionID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDailyQuizQuestionAnswerMutationVariables,
  APITypes.UpdateDailyQuizQuestionAnswerMutation
>;
export const deleteDailyQuizQuestionAnswer = /* GraphQL */ `mutation DeleteDailyQuizQuestionAnswer(
  $input: DeleteDailyQuizQuestionAnswerInput!
  $condition: ModelDailyQuizQuestionAnswerConditionInput
) {
  deleteDailyQuizQuestionAnswer(input: $input, condition: $condition) {
    id
    questionNumber
    userAnswer
    isCorrect
    dailyquizquestionID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDailyQuizQuestionAnswerMutationVariables,
  APITypes.DeleteDailyQuizQuestionAnswerMutation
>;
export const createMockExamSubject = /* GraphQL */ `mutation CreateMockExamSubject(
  $input: CreateMockExamSubjectInput!
  $condition: ModelMockExamSubjectConditionInput
) {
  createMockExamSubject(input: $input, condition: $condition) {
    id
    subjectName
    memo
    typeQuestionName
    scoreComments {
      startScore
      endScore
      typeQuestion
      evaluationStandard
      comment
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMockExamSubjectMutationVariables,
  APITypes.CreateMockExamSubjectMutation
>;
export const updateMockExamSubject = /* GraphQL */ `mutation UpdateMockExamSubject(
  $input: UpdateMockExamSubjectInput!
  $condition: ModelMockExamSubjectConditionInput
) {
  updateMockExamSubject(input: $input, condition: $condition) {
    id
    subjectName
    memo
    typeQuestionName
    scoreComments {
      startScore
      endScore
      typeQuestion
      evaluationStandard
      comment
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMockExamSubjectMutationVariables,
  APITypes.UpdateMockExamSubjectMutation
>;
export const deleteMockExamSubject = /* GraphQL */ `mutation DeleteMockExamSubject(
  $input: DeleteMockExamSubjectInput!
  $condition: ModelMockExamSubjectConditionInput
) {
  deleteMockExamSubject(input: $input, condition: $condition) {
    id
    subjectName
    memo
    typeQuestionName
    scoreComments {
      startScore
      endScore
      typeQuestion
      evaluationStandard
      comment
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMockExamSubjectMutationVariables,
  APITypes.DeleteMockExamSubjectMutation
>;
export const createMockExamBase = /* GraphQL */ `mutation CreateMockExamBase(
  $input: CreateMockExamBaseInput!
  $condition: ModelMockExamBaseConditionInput
) {
  createMockExamBase(input: $input, condition: $condition) {
    id
    title
    productIdx
    mockExamBaseIdx
    MockExamQuestions {
      nextToken
      startedAt
      __typename
    }
    mockexamcompanyID
    MockExamUsers {
      nextToken
      startedAt
      __typename
    }
    MockExamCompany {
      id
      companyName
      affiliatesType
      affiliatesName
      companyLogo
      examSubjects
      isUse
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMockExamBaseMutationVariables,
  APITypes.CreateMockExamBaseMutation
>;
export const updateMockExamBase = /* GraphQL */ `mutation UpdateMockExamBase(
  $input: UpdateMockExamBaseInput!
  $condition: ModelMockExamBaseConditionInput
) {
  updateMockExamBase(input: $input, condition: $condition) {
    id
    title
    productIdx
    mockExamBaseIdx
    MockExamQuestions {
      nextToken
      startedAt
      __typename
    }
    mockexamcompanyID
    MockExamUsers {
      nextToken
      startedAt
      __typename
    }
    MockExamCompany {
      id
      companyName
      affiliatesType
      affiliatesName
      companyLogo
      examSubjects
      isUse
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMockExamBaseMutationVariables,
  APITypes.UpdateMockExamBaseMutation
>;
export const deleteMockExamBase = /* GraphQL */ `mutation DeleteMockExamBase(
  $input: DeleteMockExamBaseInput!
  $condition: ModelMockExamBaseConditionInput
) {
  deleteMockExamBase(input: $input, condition: $condition) {
    id
    title
    productIdx
    mockExamBaseIdx
    MockExamQuestions {
      nextToken
      startedAt
      __typename
    }
    mockexamcompanyID
    MockExamUsers {
      nextToken
      startedAt
      __typename
    }
    MockExamCompany {
      id
      companyName
      affiliatesType
      affiliatesName
      companyLogo
      examSubjects
      isUse
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMockExamBaseMutationVariables,
  APITypes.DeleteMockExamBaseMutation
>;
export const createMockExamQuestion = /* GraphQL */ `mutation CreateMockExamQuestion(
  $input: CreateMockExamQuestionInput!
  $condition: ModelMockExamQuestionConditionInput
) {
  createMockExamQuestion(input: $input, condition: $condition) {
    questionCount
    optionCount
    solveLimitMinute
    pauseLimitMinute
    questionPaper
    commentaryPaper
    sortValue
    mockexambaseID
    MockExamSubject {
      id
      subjectName
      memo
      typeQuestionName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    answers {
      questionNumber
      answer
      score
      correctAnswerRate
      __typename
    }
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mockExamQuestionMockExamSubjectId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMockExamQuestionMutationVariables,
  APITypes.CreateMockExamQuestionMutation
>;
export const updateMockExamQuestion = /* GraphQL */ `mutation UpdateMockExamQuestion(
  $input: UpdateMockExamQuestionInput!
  $condition: ModelMockExamQuestionConditionInput
) {
  updateMockExamQuestion(input: $input, condition: $condition) {
    questionCount
    optionCount
    solveLimitMinute
    pauseLimitMinute
    questionPaper
    commentaryPaper
    sortValue
    mockexambaseID
    MockExamSubject {
      id
      subjectName
      memo
      typeQuestionName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    answers {
      questionNumber
      answer
      score
      correctAnswerRate
      __typename
    }
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mockExamQuestionMockExamSubjectId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMockExamQuestionMutationVariables,
  APITypes.UpdateMockExamQuestionMutation
>;
export const deleteMockExamQuestion = /* GraphQL */ `mutation DeleteMockExamQuestion(
  $input: DeleteMockExamQuestionInput!
  $condition: ModelMockExamQuestionConditionInput
) {
  deleteMockExamQuestion(input: $input, condition: $condition) {
    questionCount
    optionCount
    solveLimitMinute
    pauseLimitMinute
    questionPaper
    commentaryPaper
    sortValue
    mockexambaseID
    MockExamSubject {
      id
      subjectName
      memo
      typeQuestionName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    answers {
      questionNumber
      answer
      score
      correctAnswerRate
      __typename
    }
    id
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mockExamQuestionMockExamSubjectId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMockExamQuestionMutationVariables,
  APITypes.DeleteMockExamQuestionMutation
>;
export const createMockExamCompany = /* GraphQL */ `mutation CreateMockExamCompany(
  $input: CreateMockExamCompanyInput!
  $condition: ModelMockExamCompanyConditionInput
) {
  createMockExamCompany(input: $input, condition: $condition) {
    id
    companyName
    affiliatesType
    affiliatesName
    companyLogo
    examSubjects
    isUse
    MockExamBases {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMockExamCompanyMutationVariables,
  APITypes.CreateMockExamCompanyMutation
>;
export const updateMockExamCompany = /* GraphQL */ `mutation UpdateMockExamCompany(
  $input: UpdateMockExamCompanyInput!
  $condition: ModelMockExamCompanyConditionInput
) {
  updateMockExamCompany(input: $input, condition: $condition) {
    id
    companyName
    affiliatesType
    affiliatesName
    companyLogo
    examSubjects
    isUse
    MockExamBases {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMockExamCompanyMutationVariables,
  APITypes.UpdateMockExamCompanyMutation
>;
export const deleteMockExamCompany = /* GraphQL */ `mutation DeleteMockExamCompany(
  $input: DeleteMockExamCompanyInput!
  $condition: ModelMockExamCompanyConditionInput
) {
  deleteMockExamCompany(input: $input, condition: $condition) {
    id
    companyName
    affiliatesType
    affiliatesName
    companyLogo
    examSubjects
    isUse
    MockExamBases {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMockExamCompanyMutationVariables,
  APITypes.DeleteMockExamCompanyMutation
>;
export const createMockExamUser = /* GraphQL */ `mutation CreateMockExamUser(
  $input: CreateMockExamUserInput!
  $condition: ModelMockExamUserConditionInput
) {
  createMockExamUser(input: $input, condition: $condition) {
    id
    memberIdx
    memberId
    memberName
    affiliatesName
    memberProductIdx
    memberMocktestIdx
    MockExamUserQuestionAnswers {
      nextToken
      startedAt
      __typename
    }
    MockExamUserQuestionAnswerTemps {
      nextToken
      startedAt
      __typename
    }
    mockexambaseID
    MockExamBase {
      id
      title
      productIdx
      mockExamBaseIdx
      mockexamcompanyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMockExamUserMutationVariables,
  APITypes.CreateMockExamUserMutation
>;
export const updateMockExamUser = /* GraphQL */ `mutation UpdateMockExamUser(
  $input: UpdateMockExamUserInput!
  $condition: ModelMockExamUserConditionInput
) {
  updateMockExamUser(input: $input, condition: $condition) {
    id
    memberIdx
    memberId
    memberName
    affiliatesName
    memberProductIdx
    memberMocktestIdx
    MockExamUserQuestionAnswers {
      nextToken
      startedAt
      __typename
    }
    MockExamUserQuestionAnswerTemps {
      nextToken
      startedAt
      __typename
    }
    mockexambaseID
    MockExamBase {
      id
      title
      productIdx
      mockExamBaseIdx
      mockexamcompanyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMockExamUserMutationVariables,
  APITypes.UpdateMockExamUserMutation
>;
export const deleteMockExamUser = /* GraphQL */ `mutation DeleteMockExamUser(
  $input: DeleteMockExamUserInput!
  $condition: ModelMockExamUserConditionInput
) {
  deleteMockExamUser(input: $input, condition: $condition) {
    id
    memberIdx
    memberId
    memberName
    affiliatesName
    memberProductIdx
    memberMocktestIdx
    MockExamUserQuestionAnswers {
      nextToken
      startedAt
      __typename
    }
    MockExamUserQuestionAnswerTemps {
      nextToken
      startedAt
      __typename
    }
    mockexambaseID
    MockExamBase {
      id
      title
      productIdx
      mockExamBaseIdx
      mockexamcompanyID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMockExamUserMutationVariables,
  APITypes.DeleteMockExamUserMutation
>;
export const createMockExamUserQuestionAnswer = /* GraphQL */ `mutation CreateMockExamUserQuestionAnswer(
  $input: CreateMockExamUserQuestionAnswerInput!
  $condition: ModelMockExamUserQuestionAnswerConditionInput
) {
  createMockExamUserQuestionAnswer(input: $input, condition: $condition) {
    id
    answers {
      questionNumber
      answer
      isCorrect
      __typename
    }
    mockexamansweruserID
    MockExamQuestion {
      questionCount
      optionCount
      solveLimitMinute
      pauseLimitMinute
      questionPaper
      commentaryPaper
      sortValue
      mockexambaseID
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamQuestionMockExamSubjectId
      __typename
    }
    score
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mockExamUserQuestionAnswerMockExamQuestionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMockExamUserQuestionAnswerMutationVariables,
  APITypes.CreateMockExamUserQuestionAnswerMutation
>;
export const updateMockExamUserQuestionAnswer = /* GraphQL */ `mutation UpdateMockExamUserQuestionAnswer(
  $input: UpdateMockExamUserQuestionAnswerInput!
  $condition: ModelMockExamUserQuestionAnswerConditionInput
) {
  updateMockExamUserQuestionAnswer(input: $input, condition: $condition) {
    id
    answers {
      questionNumber
      answer
      isCorrect
      __typename
    }
    mockexamansweruserID
    MockExamQuestion {
      questionCount
      optionCount
      solveLimitMinute
      pauseLimitMinute
      questionPaper
      commentaryPaper
      sortValue
      mockexambaseID
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamQuestionMockExamSubjectId
      __typename
    }
    score
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mockExamUserQuestionAnswerMockExamQuestionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMockExamUserQuestionAnswerMutationVariables,
  APITypes.UpdateMockExamUserQuestionAnswerMutation
>;
export const deleteMockExamUserQuestionAnswer = /* GraphQL */ `mutation DeleteMockExamUserQuestionAnswer(
  $input: DeleteMockExamUserQuestionAnswerInput!
  $condition: ModelMockExamUserQuestionAnswerConditionInput
) {
  deleteMockExamUserQuestionAnswer(input: $input, condition: $condition) {
    id
    answers {
      questionNumber
      answer
      isCorrect
      __typename
    }
    mockexamansweruserID
    MockExamQuestion {
      questionCount
      optionCount
      solveLimitMinute
      pauseLimitMinute
      questionPaper
      commentaryPaper
      sortValue
      mockexambaseID
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamQuestionMockExamSubjectId
      __typename
    }
    score
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mockExamUserQuestionAnswerMockExamQuestionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMockExamUserQuestionAnswerMutationVariables,
  APITypes.DeleteMockExamUserQuestionAnswerMutation
>;
export const createMockExamUserQuestionAnswerTemp = /* GraphQL */ `mutation CreateMockExamUserQuestionAnswerTemp(
  $input: CreateMockExamUserQuestionAnswerTempInput!
  $condition: ModelMockExamUserQuestionAnswerTempConditionInput
) {
  createMockExamUserQuestionAnswerTemp(input: $input, condition: $condition) {
    id
    answers {
      questionNumber
      answer
      __typename
    }
    remainQuestionTime
    mockexamansweruserID
    MockExamQuestion {
      questionCount
      optionCount
      solveLimitMinute
      pauseLimitMinute
      questionPaper
      commentaryPaper
      sortValue
      mockexambaseID
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamQuestionMockExamSubjectId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mockExamUserQuestionAnswerTempMockExamQuestionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMockExamUserQuestionAnswerTempMutationVariables,
  APITypes.CreateMockExamUserQuestionAnswerTempMutation
>;
export const updateMockExamUserQuestionAnswerTemp = /* GraphQL */ `mutation UpdateMockExamUserQuestionAnswerTemp(
  $input: UpdateMockExamUserQuestionAnswerTempInput!
  $condition: ModelMockExamUserQuestionAnswerTempConditionInput
) {
  updateMockExamUserQuestionAnswerTemp(input: $input, condition: $condition) {
    id
    answers {
      questionNumber
      answer
      __typename
    }
    remainQuestionTime
    mockexamansweruserID
    MockExamQuestion {
      questionCount
      optionCount
      solveLimitMinute
      pauseLimitMinute
      questionPaper
      commentaryPaper
      sortValue
      mockexambaseID
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamQuestionMockExamSubjectId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mockExamUserQuestionAnswerTempMockExamQuestionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMockExamUserQuestionAnswerTempMutationVariables,
  APITypes.UpdateMockExamUserQuestionAnswerTempMutation
>;
export const deleteMockExamUserQuestionAnswerTemp = /* GraphQL */ `mutation DeleteMockExamUserQuestionAnswerTemp(
  $input: DeleteMockExamUserQuestionAnswerTempInput!
  $condition: ModelMockExamUserQuestionAnswerTempConditionInput
) {
  deleteMockExamUserQuestionAnswerTemp(input: $input, condition: $condition) {
    id
    answers {
      questionNumber
      answer
      __typename
    }
    remainQuestionTime
    mockexamansweruserID
    MockExamQuestion {
      questionCount
      optionCount
      solveLimitMinute
      pauseLimitMinute
      questionPaper
      commentaryPaper
      sortValue
      mockexambaseID
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      mockExamQuestionMockExamSubjectId
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    mockExamUserQuestionAnswerTempMockExamQuestionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMockExamUserQuestionAnswerTempMutationVariables,
  APITypes.DeleteMockExamUserQuestionAnswerTempMutation
>;
export const createCommonCode = /* GraphQL */ `mutation CreateCommonCode(
  $input: CreateCommonCodeInput!
  $condition: ModelCommonCodeConditionInput
) {
  createCommonCode(input: $input, condition: $condition) {
    id
    sitesID
    parentId
    pattern
    name
    value
    additional
    sortValue
    isUse
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommonCodeMutationVariables,
  APITypes.CreateCommonCodeMutation
>;
export const updateCommonCode = /* GraphQL */ `mutation UpdateCommonCode(
  $input: UpdateCommonCodeInput!
  $condition: ModelCommonCodeConditionInput
) {
  updateCommonCode(input: $input, condition: $condition) {
    id
    sitesID
    parentId
    pattern
    name
    value
    additional
    sortValue
    isUse
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommonCodeMutationVariables,
  APITypes.UpdateCommonCodeMutation
>;
export const deleteCommonCode = /* GraphQL */ `mutation DeleteCommonCode(
  $input: DeleteCommonCodeInput!
  $condition: ModelCommonCodeConditionInput
) {
  deleteCommonCode(input: $input, condition: $condition) {
    id
    sitesID
    parentId
    pattern
    name
    value
    additional
    sortValue
    isUse
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommonCodeMutationVariables,
  APITypes.DeleteCommonCodeMutation
>;
export const createCommonCodePattern = /* GraphQL */ `mutation CreateCommonCodePattern(
  $input: CreateCommonCodePatternInput!
  $condition: ModelCommonCodePatternConditionInput
) {
  createCommonCodePattern(input: $input, condition: $condition) {
    id
    sitesID
    pattern
    description
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommonCodePatternMutationVariables,
  APITypes.CreateCommonCodePatternMutation
>;
export const updateCommonCodePattern = /* GraphQL */ `mutation UpdateCommonCodePattern(
  $input: UpdateCommonCodePatternInput!
  $condition: ModelCommonCodePatternConditionInput
) {
  updateCommonCodePattern(input: $input, condition: $condition) {
    id
    sitesID
    pattern
    description
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommonCodePatternMutationVariables,
  APITypes.UpdateCommonCodePatternMutation
>;
export const deleteCommonCodePattern = /* GraphQL */ `mutation DeleteCommonCodePattern(
  $input: DeleteCommonCodePatternInput!
  $condition: ModelCommonCodePatternConditionInput
) {
  deleteCommonCodePattern(input: $input, condition: $condition) {
    id
    sitesID
    pattern
    description
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommonCodePatternMutationVariables,
  APITypes.DeleteCommonCodePatternMutation
>;
export const createSites = /* GraphQL */ `mutation CreateSites(
  $input: CreateSitesInput!
  $condition: ModelSitesConditionInput
) {
  createSites(input: $input, condition: $condition) {
    id
    siteName
    siteCode
    academyCode
    posCode
    metaTitle
    metaDescription
    metaKeyword
    metaImage
    Menus {
      nextToken
      startedAt
      __typename
    }
    Lectures {
      nextToken
      startedAt
      __typename
    }
    Banners {
      nextToken
      startedAt
      __typename
    }
    PopupBanners {
      nextToken
      startedAt
      __typename
    }
    Teachers {
      nextToken
      startedAt
      __typename
    }
    FreeLecturesList {
      nextToken
      startedAt
      __typename
    }
    BestFreeLecturesList {
      nextToken
      startedAt
      __typename
    }
    FreeContentsList {
      nextToken
      startedAt
      __typename
    }
    CommonCodes {
      nextToken
      startedAt
      __typename
    }
    ImageLibraries {
      nextToken
      startedAt
      __typename
    }
    SMSSends {
      nextToken
      startedAt
      __typename
    }
    SMSTemplateMsg {
      nextToken
      startedAt
      __typename
    }
    QuickMenus {
      nextToken
      startedAt
      __typename
    }
    AdminGroup {
      nextToken
      startedAt
      __typename
    }
    EventClasses {
      nextToken
      startedAt
      __typename
    }
    Events {
      nextToken
      startedAt
      __typename
    }
    FullServiceSubjects {
      nextToken
      startedAt
      __typename
    }
    FullServiceExams {
      nextToken
      startedAt
      __typename
    }
    FullServiceDisplayBoards {
      nextToken
      startedAt
      __typename
    }
    TextContents {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSitesMutationVariables,
  APITypes.CreateSitesMutation
>;
export const updateSites = /* GraphQL */ `mutation UpdateSites(
  $input: UpdateSitesInput!
  $condition: ModelSitesConditionInput
) {
  updateSites(input: $input, condition: $condition) {
    id
    siteName
    siteCode
    academyCode
    posCode
    metaTitle
    metaDescription
    metaKeyword
    metaImage
    Menus {
      nextToken
      startedAt
      __typename
    }
    Lectures {
      nextToken
      startedAt
      __typename
    }
    Banners {
      nextToken
      startedAt
      __typename
    }
    PopupBanners {
      nextToken
      startedAt
      __typename
    }
    Teachers {
      nextToken
      startedAt
      __typename
    }
    FreeLecturesList {
      nextToken
      startedAt
      __typename
    }
    BestFreeLecturesList {
      nextToken
      startedAt
      __typename
    }
    FreeContentsList {
      nextToken
      startedAt
      __typename
    }
    CommonCodes {
      nextToken
      startedAt
      __typename
    }
    ImageLibraries {
      nextToken
      startedAt
      __typename
    }
    SMSSends {
      nextToken
      startedAt
      __typename
    }
    SMSTemplateMsg {
      nextToken
      startedAt
      __typename
    }
    QuickMenus {
      nextToken
      startedAt
      __typename
    }
    AdminGroup {
      nextToken
      startedAt
      __typename
    }
    EventClasses {
      nextToken
      startedAt
      __typename
    }
    Events {
      nextToken
      startedAt
      __typename
    }
    FullServiceSubjects {
      nextToken
      startedAt
      __typename
    }
    FullServiceExams {
      nextToken
      startedAt
      __typename
    }
    FullServiceDisplayBoards {
      nextToken
      startedAt
      __typename
    }
    TextContents {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSitesMutationVariables,
  APITypes.UpdateSitesMutation
>;
export const deleteSites = /* GraphQL */ `mutation DeleteSites(
  $input: DeleteSitesInput!
  $condition: ModelSitesConditionInput
) {
  deleteSites(input: $input, condition: $condition) {
    id
    siteName
    siteCode
    academyCode
    posCode
    metaTitle
    metaDescription
    metaKeyword
    metaImage
    Menus {
      nextToken
      startedAt
      __typename
    }
    Lectures {
      nextToken
      startedAt
      __typename
    }
    Banners {
      nextToken
      startedAt
      __typename
    }
    PopupBanners {
      nextToken
      startedAt
      __typename
    }
    Teachers {
      nextToken
      startedAt
      __typename
    }
    FreeLecturesList {
      nextToken
      startedAt
      __typename
    }
    BestFreeLecturesList {
      nextToken
      startedAt
      __typename
    }
    FreeContentsList {
      nextToken
      startedAt
      __typename
    }
    CommonCodes {
      nextToken
      startedAt
      __typename
    }
    ImageLibraries {
      nextToken
      startedAt
      __typename
    }
    SMSSends {
      nextToken
      startedAt
      __typename
    }
    SMSTemplateMsg {
      nextToken
      startedAt
      __typename
    }
    QuickMenus {
      nextToken
      startedAt
      __typename
    }
    AdminGroup {
      nextToken
      startedAt
      __typename
    }
    EventClasses {
      nextToken
      startedAt
      __typename
    }
    Events {
      nextToken
      startedAt
      __typename
    }
    FullServiceSubjects {
      nextToken
      startedAt
      __typename
    }
    FullServiceExams {
      nextToken
      startedAt
      __typename
    }
    FullServiceDisplayBoards {
      nextToken
      startedAt
      __typename
    }
    TextContents {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSitesMutationVariables,
  APITypes.DeleteSitesMutation
>;
export const createMenus = /* GraphQL */ `mutation CreateMenus(
  $input: CreateMenusInput!
  $condition: ModelMenusConditionInput
) {
  createMenus(input: $input, condition: $condition) {
    id
    sitesID
    parentId
    name
    depth
    linkURL
    target
    isBold
    isUse
    isIcon
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMenusMutationVariables,
  APITypes.CreateMenusMutation
>;
export const updateMenus = /* GraphQL */ `mutation UpdateMenus(
  $input: UpdateMenusInput!
  $condition: ModelMenusConditionInput
) {
  updateMenus(input: $input, condition: $condition) {
    id
    sitesID
    parentId
    name
    depth
    linkURL
    target
    isBold
    isUse
    isIcon
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMenusMutationVariables,
  APITypes.UpdateMenusMutation
>;
export const deleteMenus = /* GraphQL */ `mutation DeleteMenus(
  $input: DeleteMenusInput!
  $condition: ModelMenusConditionInput
) {
  deleteMenus(input: $input, condition: $condition) {
    id
    sitesID
    parentId
    name
    depth
    linkURL
    target
    isBold
    isUse
    isIcon
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMenusMutationVariables,
  APITypes.DeleteMenusMutation
>;
export const createJobPostingMain = /* GraphQL */ `mutation CreateJobPostingMain(
  $input: CreateJobPostingMainInput!
  $condition: ModelJobPostingMainConditionInput
) {
  createJobPostingMain(input: $input, condition: $condition) {
    id
    sortValue
    JobPosting {
      id
      jobPostingTitle
      jobPostingTitleSub1
      jobPostingTitleSub2
      jobPostingType
      companyLogo
      jobPostingBeginDate
      jobPostingEndDate
      isDisplayMain
      displayMainSortValue
      firstDisplayItemKey
      JobPostingItemsJson
      cntView
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    jobPostingMainJobPostingId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobPostingMainMutationVariables,
  APITypes.CreateJobPostingMainMutation
>;
export const updateJobPostingMain = /* GraphQL */ `mutation UpdateJobPostingMain(
  $input: UpdateJobPostingMainInput!
  $condition: ModelJobPostingMainConditionInput
) {
  updateJobPostingMain(input: $input, condition: $condition) {
    id
    sortValue
    JobPosting {
      id
      jobPostingTitle
      jobPostingTitleSub1
      jobPostingTitleSub2
      jobPostingType
      companyLogo
      jobPostingBeginDate
      jobPostingEndDate
      isDisplayMain
      displayMainSortValue
      firstDisplayItemKey
      JobPostingItemsJson
      cntView
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    jobPostingMainJobPostingId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobPostingMainMutationVariables,
  APITypes.UpdateJobPostingMainMutation
>;
export const deleteJobPostingMain = /* GraphQL */ `mutation DeleteJobPostingMain(
  $input: DeleteJobPostingMainInput!
  $condition: ModelJobPostingMainConditionInput
) {
  deleteJobPostingMain(input: $input, condition: $condition) {
    id
    sortValue
    JobPosting {
      id
      jobPostingTitle
      jobPostingTitleSub1
      jobPostingTitleSub2
      jobPostingType
      companyLogo
      jobPostingBeginDate
      jobPostingEndDate
      isDisplayMain
      displayMainSortValue
      firstDisplayItemKey
      JobPostingItemsJson
      cntView
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    jobPostingMainJobPostingId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobPostingMainMutationVariables,
  APITypes.DeleteJobPostingMainMutation
>;
export const createJobPosting = /* GraphQL */ `mutation CreateJobPosting(
  $input: CreateJobPostingInput!
  $condition: ModelJobPostingConditionInput
) {
  createJobPosting(input: $input, condition: $condition) {
    id
    jobPostingTitle
    jobPostingTitleSub1
    jobPostingTitleSub2
    jobPostingType
    companyLogo
    jobPostingBeginDate
    jobPostingEndDate
    isDisplayMain
    displayMainSortValue
    firstDisplayItemKey
    JobPostingItemsJson
    cntView
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobPostingMutationVariables,
  APITypes.CreateJobPostingMutation
>;
export const updateJobPosting = /* GraphQL */ `mutation UpdateJobPosting(
  $input: UpdateJobPostingInput!
  $condition: ModelJobPostingConditionInput
) {
  updateJobPosting(input: $input, condition: $condition) {
    id
    jobPostingTitle
    jobPostingTitleSub1
    jobPostingTitleSub2
    jobPostingType
    companyLogo
    jobPostingBeginDate
    jobPostingEndDate
    isDisplayMain
    displayMainSortValue
    firstDisplayItemKey
    JobPostingItemsJson
    cntView
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobPostingMutationVariables,
  APITypes.UpdateJobPostingMutation
>;
export const deleteJobPosting = /* GraphQL */ `mutation DeleteJobPosting(
  $input: DeleteJobPostingInput!
  $condition: ModelJobPostingConditionInput
) {
  deleteJobPosting(input: $input, condition: $condition) {
    id
    jobPostingTitle
    jobPostingTitleSub1
    jobPostingTitleSub2
    jobPostingType
    companyLogo
    jobPostingBeginDate
    jobPostingEndDate
    isDisplayMain
    displayMainSortValue
    firstDisplayItemKey
    JobPostingItemsJson
    cntView
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobPostingMutationVariables,
  APITypes.DeleteJobPostingMutation
>;
export const createPassSpecificationsStatus = /* GraphQL */ `mutation CreatePassSpecificationsStatus(
  $input: CreatePassSpecificationsStatusInput!
  $condition: ModelPassSpecificationsStatusConditionInput
) {
  createPassSpecificationsStatus(input: $input, condition: $condition) {
    id
    passspecificationsID
    passDate
    employmentType
    supportJob
    university
    courseCredit
    toeicScore
    toeicSpeakingGrade
    certificationCount
    isOverseasExperience
    externalActivitiesCount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePassSpecificationsStatusMutationVariables,
  APITypes.CreatePassSpecificationsStatusMutation
>;
export const updatePassSpecificationsStatus = /* GraphQL */ `mutation UpdatePassSpecificationsStatus(
  $input: UpdatePassSpecificationsStatusInput!
  $condition: ModelPassSpecificationsStatusConditionInput
) {
  updatePassSpecificationsStatus(input: $input, condition: $condition) {
    id
    passspecificationsID
    passDate
    employmentType
    supportJob
    university
    courseCredit
    toeicScore
    toeicSpeakingGrade
    certificationCount
    isOverseasExperience
    externalActivitiesCount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePassSpecificationsStatusMutationVariables,
  APITypes.UpdatePassSpecificationsStatusMutation
>;
export const deletePassSpecificationsStatus = /* GraphQL */ `mutation DeletePassSpecificationsStatus(
  $input: DeletePassSpecificationsStatusInput!
  $condition: ModelPassSpecificationsStatusConditionInput
) {
  deletePassSpecificationsStatus(input: $input, condition: $condition) {
    id
    passspecificationsID
    passDate
    employmentType
    supportJob
    university
    courseCredit
    toeicScore
    toeicSpeakingGrade
    certificationCount
    isOverseasExperience
    externalActivitiesCount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePassSpecificationsStatusMutationVariables,
  APITypes.DeletePassSpecificationsStatusMutation
>;
export const createPassInterview = /* GraphQL */ `mutation CreatePassInterview(
  $input: CreatePassInterviewInput!
  $condition: ModelPassInterviewConditionInput
) {
  createPassInterview(input: $input, condition: $condition) {
    id
    passspecificationsID
    passDate
    employmentType
    supportJob
    content
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePassInterviewMutationVariables,
  APITypes.CreatePassInterviewMutation
>;
export const updatePassInterview = /* GraphQL */ `mutation UpdatePassInterview(
  $input: UpdatePassInterviewInput!
  $condition: ModelPassInterviewConditionInput
) {
  updatePassInterview(input: $input, condition: $condition) {
    id
    passspecificationsID
    passDate
    employmentType
    supportJob
    content
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePassInterviewMutationVariables,
  APITypes.UpdatePassInterviewMutation
>;
export const deletePassInterview = /* GraphQL */ `mutation DeletePassInterview(
  $input: DeletePassInterviewInput!
  $condition: ModelPassInterviewConditionInput
) {
  deletePassInterview(input: $input, condition: $condition) {
    id
    passspecificationsID
    passDate
    employmentType
    supportJob
    content
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePassInterviewMutationVariables,
  APITypes.DeletePassInterviewMutation
>;
export const createPassPersonalStatement = /* GraphQL */ `mutation CreatePassPersonalStatement(
  $input: CreatePassPersonalStatementInput!
  $condition: ModelPassPersonalStatementConditionInput
) {
  createPassPersonalStatement(input: $input, condition: $condition) {
    id
    passspecificationsID
    passDate
    employmentType
    supportJob
    content
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePassPersonalStatementMutationVariables,
  APITypes.CreatePassPersonalStatementMutation
>;
export const updatePassPersonalStatement = /* GraphQL */ `mutation UpdatePassPersonalStatement(
  $input: UpdatePassPersonalStatementInput!
  $condition: ModelPassPersonalStatementConditionInput
) {
  updatePassPersonalStatement(input: $input, condition: $condition) {
    id
    passspecificationsID
    passDate
    employmentType
    supportJob
    content
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePassPersonalStatementMutationVariables,
  APITypes.UpdatePassPersonalStatementMutation
>;
export const deletePassPersonalStatement = /* GraphQL */ `mutation DeletePassPersonalStatement(
  $input: DeletePassPersonalStatementInput!
  $condition: ModelPassPersonalStatementConditionInput
) {
  deletePassPersonalStatement(input: $input, condition: $condition) {
    id
    passspecificationsID
    passDate
    employmentType
    supportJob
    content
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePassPersonalStatementMutationVariables,
  APITypes.DeletePassPersonalStatementMutation
>;
export const createPassSpecifications = /* GraphQL */ `mutation CreatePassSpecifications(
  $input: CreatePassSpecificationsInput!
  $condition: ModelPassSpecificationsConditionInput
) {
  createPassSpecifications(input: $input, condition: $condition) {
    id
    companyName
    companyType
    companyLogo
    sortValue
    PassPersonalStatements {
      nextToken
      startedAt
      __typename
    }
    PassInterviews {
      nextToken
      startedAt
      __typename
    }
    PassSpecificationsStatuses {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePassSpecificationsMutationVariables,
  APITypes.CreatePassSpecificationsMutation
>;
export const updatePassSpecifications = /* GraphQL */ `mutation UpdatePassSpecifications(
  $input: UpdatePassSpecificationsInput!
  $condition: ModelPassSpecificationsConditionInput
) {
  updatePassSpecifications(input: $input, condition: $condition) {
    id
    companyName
    companyType
    companyLogo
    sortValue
    PassPersonalStatements {
      nextToken
      startedAt
      __typename
    }
    PassInterviews {
      nextToken
      startedAt
      __typename
    }
    PassSpecificationsStatuses {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePassSpecificationsMutationVariables,
  APITypes.UpdatePassSpecificationsMutation
>;
export const deletePassSpecifications = /* GraphQL */ `mutation DeletePassSpecifications(
  $input: DeletePassSpecificationsInput!
  $condition: ModelPassSpecificationsConditionInput
) {
  deletePassSpecifications(input: $input, condition: $condition) {
    id
    companyName
    companyType
    companyLogo
    sortValue
    PassPersonalStatements {
      nextToken
      startedAt
      __typename
    }
    PassInterviews {
      nextToken
      startedAt
      __typename
    }
    PassSpecificationsStatuses {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePassSpecificationsMutationVariables,
  APITypes.DeletePassSpecificationsMutation
>;
export const createLecture = /* GraphQL */ `mutation CreateLecture(
  $input: CreateLectureInput!
  $condition: ModelLectureConditionInput
) {
  createLecture(input: $input, condition: $condition) {
    id
    sitesID
    lectureIdx
    lectureName
    teacherName
    lectureImage
    lectureListPointText
    lectureDetailPointText
    shootingTime
    icons
    lectureLink
    eventPCLink
    eventMobileLink
    coursePeriodDescription
    averageLearningTime
    isDisplayCurriculum
    isDisplayTeacherQuestion
    isDisplayReview
    createdAt
    lectureAlerts {
      nextToken
      startedAt
      __typename
    }
    lectureIntroduces {
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLectureMutationVariables,
  APITypes.CreateLectureMutation
>;
export const updateLecture = /* GraphQL */ `mutation UpdateLecture(
  $input: UpdateLectureInput!
  $condition: ModelLectureConditionInput
) {
  updateLecture(input: $input, condition: $condition) {
    id
    sitesID
    lectureIdx
    lectureName
    teacherName
    lectureImage
    lectureListPointText
    lectureDetailPointText
    shootingTime
    icons
    lectureLink
    eventPCLink
    eventMobileLink
    coursePeriodDescription
    averageLearningTime
    isDisplayCurriculum
    isDisplayTeacherQuestion
    isDisplayReview
    createdAt
    lectureAlerts {
      nextToken
      startedAt
      __typename
    }
    lectureIntroduces {
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLectureMutationVariables,
  APITypes.UpdateLectureMutation
>;
export const deleteLecture = /* GraphQL */ `mutation DeleteLecture(
  $input: DeleteLectureInput!
  $condition: ModelLectureConditionInput
) {
  deleteLecture(input: $input, condition: $condition) {
    id
    sitesID
    lectureIdx
    lectureName
    teacherName
    lectureImage
    lectureListPointText
    lectureDetailPointText
    shootingTime
    icons
    lectureLink
    eventPCLink
    eventMobileLink
    coursePeriodDescription
    averageLearningTime
    isDisplayCurriculum
    isDisplayTeacherQuestion
    isDisplayReview
    createdAt
    lectureAlerts {
      nextToken
      startedAt
      __typename
    }
    lectureIntroduces {
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLectureMutationVariables,
  APITypes.DeleteLectureMutation
>;
export const createLectureAlert = /* GraphQL */ `mutation CreateLectureAlert(
  $input: CreateLectureAlertInput!
  $condition: ModelLectureAlertConditionInput
) {
  createLectureAlert(input: $input, condition: $condition) {
    id
    lecturesID
    lectureIdx
    isDisplay
    image
    content
    linkURL
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLectureAlertMutationVariables,
  APITypes.CreateLectureAlertMutation
>;
export const updateLectureAlert = /* GraphQL */ `mutation UpdateLectureAlert(
  $input: UpdateLectureAlertInput!
  $condition: ModelLectureAlertConditionInput
) {
  updateLectureAlert(input: $input, condition: $condition) {
    id
    lecturesID
    lectureIdx
    isDisplay
    image
    content
    linkURL
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLectureAlertMutationVariables,
  APITypes.UpdateLectureAlertMutation
>;
export const deleteLectureAlert = /* GraphQL */ `mutation DeleteLectureAlert(
  $input: DeleteLectureAlertInput!
  $condition: ModelLectureAlertConditionInput
) {
  deleteLectureAlert(input: $input, condition: $condition) {
    id
    lecturesID
    lectureIdx
    isDisplay
    image
    content
    linkURL
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLectureAlertMutationVariables,
  APITypes.DeleteLectureAlertMutation
>;
export const createLectureIntroduce = /* GraphQL */ `mutation CreateLectureIntroduce(
  $input: CreateLectureIntroduceInput!
  $condition: ModelLectureIntroduceConditionInput
) {
  createLectureIntroduce(input: $input, condition: $condition) {
    id
    lecturesID
    lectureIdx
    lectureViewType
    lectureIntroduceImages
    lectureIntroduceText
    lectureIntroduceTextMobile
    teacherViewType
    teacherIntroduceImages
    teacherIntroduceText
    teacherIntroduceTextMobile
    bookViewType
    bookIntroduceImage
    bookIntroduceText
    bookIntroduceTextMobile
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLectureIntroduceMutationVariables,
  APITypes.CreateLectureIntroduceMutation
>;
export const updateLectureIntroduce = /* GraphQL */ `mutation UpdateLectureIntroduce(
  $input: UpdateLectureIntroduceInput!
  $condition: ModelLectureIntroduceConditionInput
) {
  updateLectureIntroduce(input: $input, condition: $condition) {
    id
    lecturesID
    lectureIdx
    lectureViewType
    lectureIntroduceImages
    lectureIntroduceText
    lectureIntroduceTextMobile
    teacherViewType
    teacherIntroduceImages
    teacherIntroduceText
    teacherIntroduceTextMobile
    bookViewType
    bookIntroduceImage
    bookIntroduceText
    bookIntroduceTextMobile
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLectureIntroduceMutationVariables,
  APITypes.UpdateLectureIntroduceMutation
>;
export const deleteLectureIntroduce = /* GraphQL */ `mutation DeleteLectureIntroduce(
  $input: DeleteLectureIntroduceInput!
  $condition: ModelLectureIntroduceConditionInput
) {
  deleteLectureIntroduce(input: $input, condition: $condition) {
    id
    lecturesID
    lectureIdx
    lectureViewType
    lectureIntroduceImages
    lectureIntroduceText
    lectureIntroduceTextMobile
    teacherViewType
    teacherIntroduceImages
    teacherIntroduceText
    teacherIntroduceTextMobile
    bookViewType
    bookIntroduceImage
    bookIntroduceText
    bookIntroduceTextMobile
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLectureIntroduceMutationVariables,
  APITypes.DeleteLectureIntroduceMutation
>;
export const createSmartFinderTeacher = /* GraphQL */ `mutation CreateSmartFinderTeacher(
  $input: CreateSmartFinderTeacherInput!
  $condition: ModelSmartFinderTeacherConditionInput
) {
  createSmartFinderTeacher(input: $input, condition: $condition) {
    id
    sitesID
    CategoryItemCCDRoute
    title
    description
    subDescriptionsubTitle
    teacherImage
    teacherLinkURL
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSmartFinderTeacherMutationVariables,
  APITypes.CreateSmartFinderTeacherMutation
>;
export const updateSmartFinderTeacher = /* GraphQL */ `mutation UpdateSmartFinderTeacher(
  $input: UpdateSmartFinderTeacherInput!
  $condition: ModelSmartFinderTeacherConditionInput
) {
  updateSmartFinderTeacher(input: $input, condition: $condition) {
    id
    sitesID
    CategoryItemCCDRoute
    title
    description
    subDescriptionsubTitle
    teacherImage
    teacherLinkURL
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSmartFinderTeacherMutationVariables,
  APITypes.UpdateSmartFinderTeacherMutation
>;
export const deleteSmartFinderTeacher = /* GraphQL */ `mutation DeleteSmartFinderTeacher(
  $input: DeleteSmartFinderTeacherInput!
  $condition: ModelSmartFinderTeacherConditionInput
) {
  deleteSmartFinderTeacher(input: $input, condition: $condition) {
    id
    sitesID
    CategoryItemCCDRoute
    title
    description
    subDescriptionsubTitle
    teacherImage
    teacherLinkURL
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSmartFinderTeacherMutationVariables,
  APITypes.DeleteSmartFinderTeacherMutation
>;
export const createBanner = /* GraphQL */ `mutation CreateBanner(
  $input: CreateBannerInput!
  $condition: ModelBannerConditionInput
) {
  createBanner(input: $input, condition: $condition) {
    id
    sitesID
    bannerClassCodeDepth1
    bannerClassCodeDepth2
    bannerClassCodeDepth3
    bannerClassCodeDepth4
    bannerTypeCode
    title
    sortValue
    description
    bannerViewTypeCode
    viewIFrameURL
    viewImageURL
    viewHTML
    imageAlt
    deadlineText
    isUseAlert
    bannerTargetCode
    linkURL
    productIdx
    bookId
    displayBeginDate
    displayEndDate
    displayBeginDateTime
    displayEndDateTime
    backgroundColor
    utmParam
    isUse
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBannerMutationVariables,
  APITypes.CreateBannerMutation
>;
export const updateBanner = /* GraphQL */ `mutation UpdateBanner(
  $input: UpdateBannerInput!
  $condition: ModelBannerConditionInput
) {
  updateBanner(input: $input, condition: $condition) {
    id
    sitesID
    bannerClassCodeDepth1
    bannerClassCodeDepth2
    bannerClassCodeDepth3
    bannerClassCodeDepth4
    bannerTypeCode
    title
    sortValue
    description
    bannerViewTypeCode
    viewIFrameURL
    viewImageURL
    viewHTML
    imageAlt
    deadlineText
    isUseAlert
    bannerTargetCode
    linkURL
    productIdx
    bookId
    displayBeginDate
    displayEndDate
    displayBeginDateTime
    displayEndDateTime
    backgroundColor
    utmParam
    isUse
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBannerMutationVariables,
  APITypes.UpdateBannerMutation
>;
export const deleteBanner = /* GraphQL */ `mutation DeleteBanner(
  $input: DeleteBannerInput!
  $condition: ModelBannerConditionInput
) {
  deleteBanner(input: $input, condition: $condition) {
    id
    sitesID
    bannerClassCodeDepth1
    bannerClassCodeDepth2
    bannerClassCodeDepth3
    bannerClassCodeDepth4
    bannerTypeCode
    title
    sortValue
    description
    bannerViewTypeCode
    viewIFrameURL
    viewImageURL
    viewHTML
    imageAlt
    deadlineText
    isUseAlert
    bannerTargetCode
    linkURL
    productIdx
    bookId
    displayBeginDate
    displayEndDate
    displayBeginDateTime
    displayEndDateTime
    backgroundColor
    utmParam
    isUse
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBannerMutationVariables,
  APITypes.DeleteBannerMutation
>;
export const createPopupBanner = /* GraphQL */ `mutation CreatePopupBanner(
  $input: CreatePopupBannerInput!
  $condition: ModelPopupBannerConditionInput
) {
  createPopupBanner(input: $input, condition: $condition) {
    id
    sitesID
    title
    bannerFunctionType
    exposureLocation
    isTimeBoard
    isShake
    displayBeginDate
    displayEndDate
    displayBeginDateTime
    displayEndDateTime
    displayLocationX
    displayLocationY
    isCalcRelative
    isAlignCenter
    bannerViewTypeCode
    viewIFrameURL
    viewImageURL
    bannerTargetCode
    mapLinks {
      coords
      target
      linkURL
      linkAlt
      __typename
    }
    linkURL
    sortValue
    isUse
    isDisplayPC
    isDisplayMobile
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePopupBannerMutationVariables,
  APITypes.CreatePopupBannerMutation
>;
export const updatePopupBanner = /* GraphQL */ `mutation UpdatePopupBanner(
  $input: UpdatePopupBannerInput!
  $condition: ModelPopupBannerConditionInput
) {
  updatePopupBanner(input: $input, condition: $condition) {
    id
    sitesID
    title
    bannerFunctionType
    exposureLocation
    isTimeBoard
    isShake
    displayBeginDate
    displayEndDate
    displayBeginDateTime
    displayEndDateTime
    displayLocationX
    displayLocationY
    isCalcRelative
    isAlignCenter
    bannerViewTypeCode
    viewIFrameURL
    viewImageURL
    bannerTargetCode
    mapLinks {
      coords
      target
      linkURL
      linkAlt
      __typename
    }
    linkURL
    sortValue
    isUse
    isDisplayPC
    isDisplayMobile
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePopupBannerMutationVariables,
  APITypes.UpdatePopupBannerMutation
>;
export const deletePopupBanner = /* GraphQL */ `mutation DeletePopupBanner(
  $input: DeletePopupBannerInput!
  $condition: ModelPopupBannerConditionInput
) {
  deletePopupBanner(input: $input, condition: $condition) {
    id
    sitesID
    title
    bannerFunctionType
    exposureLocation
    isTimeBoard
    isShake
    displayBeginDate
    displayEndDate
    displayBeginDateTime
    displayEndDateTime
    displayLocationX
    displayLocationY
    isCalcRelative
    isAlignCenter
    bannerViewTypeCode
    viewIFrameURL
    viewImageURL
    bannerTargetCode
    mapLinks {
      coords
      target
      linkURL
      linkAlt
      __typename
    }
    linkURL
    sortValue
    isUse
    isDisplayPC
    isDisplayMobile
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePopupBannerMutationVariables,
  APITypes.DeletePopupBannerMutation
>;
export const createTeacher = /* GraphQL */ `mutation CreateTeacher(
  $input: CreateTeacherInput!
  $condition: ModelTeacherConditionInput
) {
  createTeacher(input: $input, condition: $condition) {
    id
    sitesID
    teacherIdx
    teacherListImage
    teacherSubjectLv3
    teacherSubject
    teacherSubjectCategory
    representingLectures
    bestLectures
    curriculums
    lectures
    events
    academyRepresentingLectures
    academyBestLectures
    academyCurriculums
    academyLectures
    academyEvents
    teacherListMainImage
    teacherListImageOn
    teacherListImageOff
    teacherMobileListImageOn
    teacherMobileListImageOff
    teacherIntroduce
    displayTeacherName
    iconText
    description
    lectureListImage
    teacherDetailImage
    teacherMobileDetailImage
    teacherCurriculumImage
    teacherIconImage
    targetScore
    titleTextTop
    titleTextSubject
    otVideoURL
    otVideoThumbnail
    representingVideoURL
    representingVideoThumbnail
    sampleVideoURL
    sampleVideoThumbnail
    reasonText
    lectureReviewVideo {
      video
      thumbnail
      __typename
    }
    curriculumImage
    curriculumContent
    curriculumText
    curriculumURL
    recommandPackage
    recommandPackageMobile
    teacherIntroduceDetailImage
    teacherIntroduceDetailMobileImage
    teacherHighScoreImage
    teacherHighScoreMobileImage
    teacherStepImage
    teacherStepMobileImage
    bestLectureSample {
      title
      url
      thumbnail
      __typename
    }
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeacherMutationVariables,
  APITypes.CreateTeacherMutation
>;
export const updateTeacher = /* GraphQL */ `mutation UpdateTeacher(
  $input: UpdateTeacherInput!
  $condition: ModelTeacherConditionInput
) {
  updateTeacher(input: $input, condition: $condition) {
    id
    sitesID
    teacherIdx
    teacherListImage
    teacherSubjectLv3
    teacherSubject
    teacherSubjectCategory
    representingLectures
    bestLectures
    curriculums
    lectures
    events
    academyRepresentingLectures
    academyBestLectures
    academyCurriculums
    academyLectures
    academyEvents
    teacherListMainImage
    teacherListImageOn
    teacherListImageOff
    teacherMobileListImageOn
    teacherMobileListImageOff
    teacherIntroduce
    displayTeacherName
    iconText
    description
    lectureListImage
    teacherDetailImage
    teacherMobileDetailImage
    teacherCurriculumImage
    teacherIconImage
    targetScore
    titleTextTop
    titleTextSubject
    otVideoURL
    otVideoThumbnail
    representingVideoURL
    representingVideoThumbnail
    sampleVideoURL
    sampleVideoThumbnail
    reasonText
    lectureReviewVideo {
      video
      thumbnail
      __typename
    }
    curriculumImage
    curriculumContent
    curriculumText
    curriculumURL
    recommandPackage
    recommandPackageMobile
    teacherIntroduceDetailImage
    teacherIntroduceDetailMobileImage
    teacherHighScoreImage
    teacherHighScoreMobileImage
    teacherStepImage
    teacherStepMobileImage
    bestLectureSample {
      title
      url
      thumbnail
      __typename
    }
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeacherMutationVariables,
  APITypes.UpdateTeacherMutation
>;
export const deleteTeacher = /* GraphQL */ `mutation DeleteTeacher(
  $input: DeleteTeacherInput!
  $condition: ModelTeacherConditionInput
) {
  deleteTeacher(input: $input, condition: $condition) {
    id
    sitesID
    teacherIdx
    teacherListImage
    teacherSubjectLv3
    teacherSubject
    teacherSubjectCategory
    representingLectures
    bestLectures
    curriculums
    lectures
    events
    academyRepresentingLectures
    academyBestLectures
    academyCurriculums
    academyLectures
    academyEvents
    teacherListMainImage
    teacherListImageOn
    teacherListImageOff
    teacherMobileListImageOn
    teacherMobileListImageOff
    teacherIntroduce
    displayTeacherName
    iconText
    description
    lectureListImage
    teacherDetailImage
    teacherMobileDetailImage
    teacherCurriculumImage
    teacherIconImage
    targetScore
    titleTextTop
    titleTextSubject
    otVideoURL
    otVideoThumbnail
    representingVideoURL
    representingVideoThumbnail
    sampleVideoURL
    sampleVideoThumbnail
    reasonText
    lectureReviewVideo {
      video
      thumbnail
      __typename
    }
    curriculumImage
    curriculumContent
    curriculumText
    curriculumURL
    recommandPackage
    recommandPackageMobile
    teacherIntroduceDetailImage
    teacherIntroduceDetailMobileImage
    teacherHighScoreImage
    teacherHighScoreMobileImage
    teacherStepImage
    teacherStepMobileImage
    bestLectureSample {
      title
      url
      thumbnail
      __typename
    }
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeacherMutationVariables,
  APITypes.DeleteTeacherMutation
>;
export const createFreeContentsItemLog = /* GraphQL */ `mutation CreateFreeContentsItemLog(
  $input: CreateFreeContentsItemLogInput!
  $condition: ModelFreeContentsItemLogConditionInput
) {
  createFreeContentsItemLog(input: $input, condition: $condition) {
    id
    memberIdx
    freeContentsID
    freeContentsIconCode
    authenticationTypeCode
    itemName
    freeContentsItemTypeCode
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFreeContentsItemLogMutationVariables,
  APITypes.CreateFreeContentsItemLogMutation
>;
export const updateFreeContentsItemLog = /* GraphQL */ `mutation UpdateFreeContentsItemLog(
  $input: UpdateFreeContentsItemLogInput!
  $condition: ModelFreeContentsItemLogConditionInput
) {
  updateFreeContentsItemLog(input: $input, condition: $condition) {
    id
    memberIdx
    freeContentsID
    freeContentsIconCode
    authenticationTypeCode
    itemName
    freeContentsItemTypeCode
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFreeContentsItemLogMutationVariables,
  APITypes.UpdateFreeContentsItemLogMutation
>;
export const deleteFreeContentsItemLog = /* GraphQL */ `mutation DeleteFreeContentsItemLog(
  $input: DeleteFreeContentsItemLogInput!
  $condition: ModelFreeContentsItemLogConditionInput
) {
  deleteFreeContentsItemLog(input: $input, condition: $condition) {
    id
    memberIdx
    freeContentsID
    freeContentsIconCode
    authenticationTypeCode
    itemName
    freeContentsItemTypeCode
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFreeContentsItemLogMutationVariables,
  APITypes.DeleteFreeContentsItemLogMutation
>;
export const createFreeContents = /* GraphQL */ `mutation CreateFreeContents(
  $input: CreateFreeContentsInput!
  $condition: ModelFreeContentsConditionInput
) {
  createFreeContents(input: $input, condition: $condition) {
    id
    sitesID
    bookId
    bookName
    bookImg
    isPublic
    showLabelCode
    difficultyCode
    purchaseURL
    videoLessonURL
    fieldLessonURL
    authentications {
      authenticationTypeCode
      authenticationValue
      __typename
    }
    freeContentsItems {
      freeContentsIconCode
      authenticationTypeCode
      itemName
      introduceText
      alertText
      freeContentsItemTypeCode
      freeContentsItem
      __typename
    }
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFreeContentsMutationVariables,
  APITypes.CreateFreeContentsMutation
>;
export const updateFreeContents = /* GraphQL */ `mutation UpdateFreeContents(
  $input: UpdateFreeContentsInput!
  $condition: ModelFreeContentsConditionInput
) {
  updateFreeContents(input: $input, condition: $condition) {
    id
    sitesID
    bookId
    bookName
    bookImg
    isPublic
    showLabelCode
    difficultyCode
    purchaseURL
    videoLessonURL
    fieldLessonURL
    authentications {
      authenticationTypeCode
      authenticationValue
      __typename
    }
    freeContentsItems {
      freeContentsIconCode
      authenticationTypeCode
      itemName
      introduceText
      alertText
      freeContentsItemTypeCode
      freeContentsItem
      __typename
    }
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFreeContentsMutationVariables,
  APITypes.UpdateFreeContentsMutation
>;
export const deleteFreeContents = /* GraphQL */ `mutation DeleteFreeContents(
  $input: DeleteFreeContentsInput!
  $condition: ModelFreeContentsConditionInput
) {
  deleteFreeContents(input: $input, condition: $condition) {
    id
    sitesID
    bookId
    bookName
    bookImg
    isPublic
    showLabelCode
    difficultyCode
    purchaseURL
    videoLessonURL
    fieldLessonURL
    authentications {
      authenticationTypeCode
      authenticationValue
      __typename
    }
    freeContentsItems {
      freeContentsIconCode
      authenticationTypeCode
      itemName
      introduceText
      alertText
      freeContentsItemTypeCode
      freeContentsItem
      __typename
    }
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFreeContentsMutationVariables,
  APITypes.DeleteFreeContentsMutation
>;
export const createBestFreeLectures = /* GraphQL */ `mutation CreateBestFreeLectures(
  $input: CreateBestFreeLecturesInput!
  $condition: ModelBestFreeLecturesConditionInput
) {
  createBestFreeLectures(input: $input, condition: $condition) {
    id
    sitesID
    title
    description
    freeLectureImage
    videoTypeCode
    videoURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBestFreeLecturesMutationVariables,
  APITypes.CreateBestFreeLecturesMutation
>;
export const updateBestFreeLectures = /* GraphQL */ `mutation UpdateBestFreeLectures(
  $input: UpdateBestFreeLecturesInput!
  $condition: ModelBestFreeLecturesConditionInput
) {
  updateBestFreeLectures(input: $input, condition: $condition) {
    id
    sitesID
    title
    description
    freeLectureImage
    videoTypeCode
    videoURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBestFreeLecturesMutationVariables,
  APITypes.UpdateBestFreeLecturesMutation
>;
export const deleteBestFreeLectures = /* GraphQL */ `mutation DeleteBestFreeLectures(
  $input: DeleteBestFreeLecturesInput!
  $condition: ModelBestFreeLecturesConditionInput
) {
  deleteBestFreeLectures(input: $input, condition: $condition) {
    id
    sitesID
    title
    description
    freeLectureImage
    videoTypeCode
    videoURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBestFreeLecturesMutationVariables,
  APITypes.DeleteBestFreeLecturesMutation
>;
export const createFreeLectures = /* GraphQL */ `mutation CreateFreeLectures(
  $input: CreateFreeLecturesInput!
  $condition: ModelFreeLecturesConditionInput
) {
  createFreeLectures(input: $input, condition: $condition) {
    id
    sitesID
    title
    description
    thumbnail
    freeLectureClassCodeDepth1
    freeLectureClassCodeDepth2
    freeLectureClassCodeDepth3
    freeLectureClassCodeDepth4
    videoTypeCode
    videoURL
    videoTimeHour
    videoTimeMinute
    videoTimeSecond
    teacherName
    lectureInfoURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFreeLecturesMutationVariables,
  APITypes.CreateFreeLecturesMutation
>;
export const updateFreeLectures = /* GraphQL */ `mutation UpdateFreeLectures(
  $input: UpdateFreeLecturesInput!
  $condition: ModelFreeLecturesConditionInput
) {
  updateFreeLectures(input: $input, condition: $condition) {
    id
    sitesID
    title
    description
    thumbnail
    freeLectureClassCodeDepth1
    freeLectureClassCodeDepth2
    freeLectureClassCodeDepth3
    freeLectureClassCodeDepth4
    videoTypeCode
    videoURL
    videoTimeHour
    videoTimeMinute
    videoTimeSecond
    teacherName
    lectureInfoURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFreeLecturesMutationVariables,
  APITypes.UpdateFreeLecturesMutation
>;
export const deleteFreeLectures = /* GraphQL */ `mutation DeleteFreeLectures(
  $input: DeleteFreeLecturesInput!
  $condition: ModelFreeLecturesConditionInput
) {
  deleteFreeLectures(input: $input, condition: $condition) {
    id
    sitesID
    title
    description
    thumbnail
    freeLectureClassCodeDepth1
    freeLectureClassCodeDepth2
    freeLectureClassCodeDepth3
    freeLectureClassCodeDepth4
    videoTypeCode
    videoURL
    videoTimeHour
    videoTimeMinute
    videoTimeSecond
    teacherName
    lectureInfoURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFreeLecturesMutationVariables,
  APITypes.DeleteFreeLecturesMutation
>;
export const createImageLibrary = /* GraphQL */ `mutation CreateImageLibrary(
  $input: CreateImageLibraryInput!
  $condition: ModelImageLibraryConditionInput
) {
  createImageLibrary(input: $input, condition: $condition) {
    id
    sitesID
    imageURL
    resolution
    size
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateImageLibraryMutationVariables,
  APITypes.CreateImageLibraryMutation
>;
export const updateImageLibrary = /* GraphQL */ `mutation UpdateImageLibrary(
  $input: UpdateImageLibraryInput!
  $condition: ModelImageLibraryConditionInput
) {
  updateImageLibrary(input: $input, condition: $condition) {
    id
    sitesID
    imageURL
    resolution
    size
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateImageLibraryMutationVariables,
  APITypes.UpdateImageLibraryMutation
>;
export const deleteImageLibrary = /* GraphQL */ `mutation DeleteImageLibrary(
  $input: DeleteImageLibraryInput!
  $condition: ModelImageLibraryConditionInput
) {
  deleteImageLibrary(input: $input, condition: $condition) {
    id
    sitesID
    imageURL
    resolution
    size
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteImageLibraryMutationVariables,
  APITypes.DeleteImageLibraryMutation
>;
export const createBookIntroduce = /* GraphQL */ `mutation CreateBookIntroduce(
  $input: CreateBookIntroduceInput!
  $condition: ModelBookIntroduceConditionInput
) {
  createBookIntroduce(input: $input, condition: $condition) {
    id
    sitesID
    bookId
    content
    isIntroduceUse
    bookLecture
    withBuyBooks
    bookInfos {
      title
      content
      mobileContent
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBookIntroduceMutationVariables,
  APITypes.CreateBookIntroduceMutation
>;
export const updateBookIntroduce = /* GraphQL */ `mutation UpdateBookIntroduce(
  $input: UpdateBookIntroduceInput!
  $condition: ModelBookIntroduceConditionInput
) {
  updateBookIntroduce(input: $input, condition: $condition) {
    id
    sitesID
    bookId
    content
    isIntroduceUse
    bookLecture
    withBuyBooks
    bookInfos {
      title
      content
      mobileContent
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBookIntroduceMutationVariables,
  APITypes.UpdateBookIntroduceMutation
>;
export const deleteBookIntroduce = /* GraphQL */ `mutation DeleteBookIntroduce(
  $input: DeleteBookIntroduceInput!
  $condition: ModelBookIntroduceConditionInput
) {
  deleteBookIntroduce(input: $input, condition: $condition) {
    id
    sitesID
    bookId
    content
    isIntroduceUse
    bookLecture
    withBuyBooks
    bookInfos {
      title
      content
      mobileContent
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBookIntroduceMutationVariables,
  APITypes.DeleteBookIntroduceMutation
>;
export const createBoardType = /* GraphQL */ `mutation CreateBoardType(
  $input: CreateBoardTypeInput!
  $condition: ModelBoardTypeConditionInput
) {
  createBoardType(input: $input, condition: $condition) {
    id
    sitesID
    typeCode
    typeName
    typeCategoryPattern
    template
    isUseFile
    isUseLecture
    isUseTeacher
    isUseSecret
    isUseComment
    isUseScore
    isUseCategory
    isUseThumbnail
    isUseVideo
    isUseLink
    isOnlyAdminWrite
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBoardTypeMutationVariables,
  APITypes.CreateBoardTypeMutation
>;
export const updateBoardType = /* GraphQL */ `mutation UpdateBoardType(
  $input: UpdateBoardTypeInput!
  $condition: ModelBoardTypeConditionInput
) {
  updateBoardType(input: $input, condition: $condition) {
    id
    sitesID
    typeCode
    typeName
    typeCategoryPattern
    template
    isUseFile
    isUseLecture
    isUseTeacher
    isUseSecret
    isUseComment
    isUseScore
    isUseCategory
    isUseThumbnail
    isUseVideo
    isUseLink
    isOnlyAdminWrite
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBoardTypeMutationVariables,
  APITypes.UpdateBoardTypeMutation
>;
export const deleteBoardType = /* GraphQL */ `mutation DeleteBoardType(
  $input: DeleteBoardTypeInput!
  $condition: ModelBoardTypeConditionInput
) {
  deleteBoardType(input: $input, condition: $condition) {
    id
    sitesID
    typeCode
    typeName
    typeCategoryPattern
    template
    isUseFile
    isUseLecture
    isUseTeacher
    isUseSecret
    isUseComment
    isUseScore
    isUseCategory
    isUseThumbnail
    isUseVideo
    isUseLink
    isOnlyAdminWrite
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBoardTypeMutationVariables,
  APITypes.DeleteBoardTypeMutation
>;
export const createBoard = /* GraphQL */ `mutation CreateBoard(
  $input: CreateBoardInput!
  $condition: ModelBoardConditionInput
) {
  createBoard(input: $input, condition: $condition) {
    id
    sitesID
    typeCode
    categoryCode
    businessName
    businessPosition
    description
    lectureIdx
    productIdx
    bookId
    teacherIdx
    title
    content
    files {
      fileName
      size
      url
      regDatetime
      __typename
    }
    thumbnail
    videoURL
    linkImage
    linkURL
    location
    viewCount
    score
    isDisplay
    isBest
    isNotice
    sortValue
    comments {
      nextToken
      startedAt
      __typename
    }
    answer
    answerMemberIdx
    answerMemberId
    answerMemberName
    regMemberIdx
    regMemberId
    regMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBoardMutationVariables,
  APITypes.CreateBoardMutation
>;
export const updateBoard = /* GraphQL */ `mutation UpdateBoard(
  $input: UpdateBoardInput!
  $condition: ModelBoardConditionInput
) {
  updateBoard(input: $input, condition: $condition) {
    id
    sitesID
    typeCode
    categoryCode
    businessName
    businessPosition
    description
    lectureIdx
    productIdx
    bookId
    teacherIdx
    title
    content
    files {
      fileName
      size
      url
      regDatetime
      __typename
    }
    thumbnail
    videoURL
    linkImage
    linkURL
    location
    viewCount
    score
    isDisplay
    isBest
    isNotice
    sortValue
    comments {
      nextToken
      startedAt
      __typename
    }
    answer
    answerMemberIdx
    answerMemberId
    answerMemberName
    regMemberIdx
    regMemberId
    regMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBoardMutationVariables,
  APITypes.UpdateBoardMutation
>;
export const deleteBoard = /* GraphQL */ `mutation DeleteBoard(
  $input: DeleteBoardInput!
  $condition: ModelBoardConditionInput
) {
  deleteBoard(input: $input, condition: $condition) {
    id
    sitesID
    typeCode
    categoryCode
    businessName
    businessPosition
    description
    lectureIdx
    productIdx
    bookId
    teacherIdx
    title
    content
    files {
      fileName
      size
      url
      regDatetime
      __typename
    }
    thumbnail
    videoURL
    linkImage
    linkURL
    location
    viewCount
    score
    isDisplay
    isBest
    isNotice
    sortValue
    comments {
      nextToken
      startedAt
      __typename
    }
    answer
    answerMemberIdx
    answerMemberId
    answerMemberName
    regMemberIdx
    regMemberId
    regMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBoardMutationVariables,
  APITypes.DeleteBoardMutation
>;
export const createComment = /* GraphQL */ `mutation CreateComment(
  $input: CreateCommentInput!
  $condition: ModelCommentConditionInput
) {
  createComment(input: $input, condition: $condition) {
    id
    sitesID
    boardsID
    parentId
    content
    regMemberIdx
    regMemberId
    regMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentMutationVariables,
  APITypes.CreateCommentMutation
>;
export const updateComment = /* GraphQL */ `mutation UpdateComment(
  $input: UpdateCommentInput!
  $condition: ModelCommentConditionInput
) {
  updateComment(input: $input, condition: $condition) {
    id
    sitesID
    boardsID
    parentId
    content
    regMemberIdx
    regMemberId
    regMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentMutationVariables,
  APITypes.UpdateCommentMutation
>;
export const deleteComment = /* GraphQL */ `mutation DeleteComment(
  $input: DeleteCommentInput!
  $condition: ModelCommentConditionInput
) {
  deleteComment(input: $input, condition: $condition) {
    id
    sitesID
    boardsID
    parentId
    content
    regMemberIdx
    regMemberId
    regMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentMutationVariables,
  APITypes.DeleteCommentMutation
>;
export const createAdmin = /* GraphQL */ `mutation CreateAdmin(
  $input: CreateAdminInput!
  $condition: ModelAdminConditionInput
) {
  createAdmin(input: $input, condition: $condition) {
    id
    memberIdx
    memberId
    memberName
    memberPw
    memberState
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminMutationVariables,
  APITypes.CreateAdminMutation
>;
export const updateAdmin = /* GraphQL */ `mutation UpdateAdmin(
  $input: UpdateAdminInput!
  $condition: ModelAdminConditionInput
) {
  updateAdmin(input: $input, condition: $condition) {
    id
    memberIdx
    memberId
    memberName
    memberPw
    memberState
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminMutationVariables,
  APITypes.UpdateAdminMutation
>;
export const deleteAdmin = /* GraphQL */ `mutation DeleteAdmin(
  $input: DeleteAdminInput!
  $condition: ModelAdminConditionInput
) {
  deleteAdmin(input: $input, condition: $condition) {
    id
    memberIdx
    memberId
    memberName
    memberPw
    memberState
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminMutationVariables,
  APITypes.DeleteAdminMutation
>;
export const createAdminGroup = /* GraphQL */ `mutation CreateAdminGroup(
  $input: CreateAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  createAdminGroup(input: $input, condition: $condition) {
    id
    sitesID
    groupName
    intranetMenuCode
    ismsLevel
    menuCode
    Site {
      id
      siteName
      siteCode
      academyCode
      posCode
      metaTitle
      metaDescription
      metaKeyword
      metaImage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminGroupMutationVariables,
  APITypes.CreateAdminGroupMutation
>;
export const updateAdminGroup = /* GraphQL */ `mutation UpdateAdminGroup(
  $input: UpdateAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  updateAdminGroup(input: $input, condition: $condition) {
    id
    sitesID
    groupName
    intranetMenuCode
    ismsLevel
    menuCode
    Site {
      id
      siteName
      siteCode
      academyCode
      posCode
      metaTitle
      metaDescription
      metaKeyword
      metaImage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminGroupMutationVariables,
  APITypes.UpdateAdminGroupMutation
>;
export const deleteAdminGroup = /* GraphQL */ `mutation DeleteAdminGroup(
  $input: DeleteAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  deleteAdminGroup(input: $input, condition: $condition) {
    id
    sitesID
    groupName
    intranetMenuCode
    ismsLevel
    menuCode
    Site {
      id
      siteName
      siteCode
      academyCode
      posCode
      metaTitle
      metaDescription
      metaKeyword
      metaImage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminGroupMutationVariables,
  APITypes.DeleteAdminGroupMutation
>;
export const createAdminLoginLog = /* GraphQL */ `mutation CreateAdminLoginLog(
  $input: CreateAdminLoginLogInput!
  $condition: ModelAdminLoginLogConditionInput
) {
  createAdminLoginLog(input: $input, condition: $condition) {
    id
    memberIdx
    memberId
    ip
    groupName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminLoginLogMutationVariables,
  APITypes.CreateAdminLoginLogMutation
>;
export const updateAdminLoginLog = /* GraphQL */ `mutation UpdateAdminLoginLog(
  $input: UpdateAdminLoginLogInput!
  $condition: ModelAdminLoginLogConditionInput
) {
  updateAdminLoginLog(input: $input, condition: $condition) {
    id
    memberIdx
    memberId
    ip
    groupName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminLoginLogMutationVariables,
  APITypes.UpdateAdminLoginLogMutation
>;
export const deleteAdminLoginLog = /* GraphQL */ `mutation DeleteAdminLoginLog(
  $input: DeleteAdminLoginLogInput!
  $condition: ModelAdminLoginLogConditionInput
) {
  deleteAdminLoginLog(input: $input, condition: $condition) {
    id
    memberIdx
    memberId
    ip
    groupName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminLoginLogMutationVariables,
  APITypes.DeleteAdminLoginLogMutation
>;
export const createAdminMenus = /* GraphQL */ `mutation CreateAdminMenus(
  $input: CreateAdminMenusInput!
  $condition: ModelAdminMenusConditionInput
) {
  createAdminMenus(input: $input, condition: $condition) {
    id
    sitesID
    groupCode
    menuName
    parentId
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminMenusMutationVariables,
  APITypes.CreateAdminMenusMutation
>;
export const updateAdminMenus = /* GraphQL */ `mutation UpdateAdminMenus(
  $input: UpdateAdminMenusInput!
  $condition: ModelAdminMenusConditionInput
) {
  updateAdminMenus(input: $input, condition: $condition) {
    id
    sitesID
    groupCode
    menuName
    parentId
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminMenusMutationVariables,
  APITypes.UpdateAdminMenusMutation
>;
export const deleteAdminMenus = /* GraphQL */ `mutation DeleteAdminMenus(
  $input: DeleteAdminMenusInput!
  $condition: ModelAdminMenusConditionInput
) {
  deleteAdminMenus(input: $input, condition: $condition) {
    id
    sitesID
    groupCode
    menuName
    parentId
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminMenusMutationVariables,
  APITypes.DeleteAdminMenusMutation
>;
export const createQuickMenu = /* GraphQL */ `mutation CreateQuickMenu(
  $input: CreateQuickMenuInput!
  $condition: ModelQuickMenuConditionInput
) {
  createQuickMenu(input: $input, condition: $condition) {
    id
    sitesID
    menuName
    icon
    linkURL
    target
    isUse
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQuickMenuMutationVariables,
  APITypes.CreateQuickMenuMutation
>;
export const updateQuickMenu = /* GraphQL */ `mutation UpdateQuickMenu(
  $input: UpdateQuickMenuInput!
  $condition: ModelQuickMenuConditionInput
) {
  updateQuickMenu(input: $input, condition: $condition) {
    id
    sitesID
    menuName
    icon
    linkURL
    target
    isUse
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQuickMenuMutationVariables,
  APITypes.UpdateQuickMenuMutation
>;
export const deleteQuickMenu = /* GraphQL */ `mutation DeleteQuickMenu(
  $input: DeleteQuickMenuInput!
  $condition: ModelQuickMenuConditionInput
) {
  deleteQuickMenu(input: $input, condition: $condition) {
    id
    sitesID
    menuName
    icon
    linkURL
    target
    isUse
    regMemberIdx
    regMemberId
    regMemberName
    modifyMemberIdx
    modifyMemberId
    modifyMemberName
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQuickMenuMutationVariables,
  APITypes.DeleteQuickMenuMutation
>;
export const createEventClass = /* GraphQL */ `mutation CreateEventClass(
  $input: CreateEventClassInput!
  $condition: ModelEventClassConditionInput
) {
  createEventClass(input: $input, condition: $condition) {
    id
    sitesID
    mainCategoryClassCode
    mainCategoryClassName
    subCategoryClassCode
    subCategoryClassName
    ableFunctionCodes
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventClassMutationVariables,
  APITypes.CreateEventClassMutation
>;
export const updateEventClass = /* GraphQL */ `mutation UpdateEventClass(
  $input: UpdateEventClassInput!
  $condition: ModelEventClassConditionInput
) {
  updateEventClass(input: $input, condition: $condition) {
    id
    sitesID
    mainCategoryClassCode
    mainCategoryClassName
    subCategoryClassCode
    subCategoryClassName
    ableFunctionCodes
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventClassMutationVariables,
  APITypes.UpdateEventClassMutation
>;
export const deleteEventClass = /* GraphQL */ `mutation DeleteEventClass(
  $input: DeleteEventClassInput!
  $condition: ModelEventClassConditionInput
) {
  deleteEventClass(input: $input, condition: $condition) {
    id
    sitesID
    mainCategoryClassCode
    mainCategoryClassName
    subCategoryClassCode
    subCategoryClassName
    ableFunctionCodes
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventClassMutationVariables,
  APITypes.DeleteEventClassMutation
>;
export const createEvent = /* GraphQL */ `mutation CreateEvent(
  $input: CreateEventInput!
  $condition: ModelEventConditionInput
) {
  createEvent(input: $input, condition: $condition) {
    id
    sitesID
    mainCategoryClassCode
    subCategoryClassCode
    eventCode
    eventName
    eventURL
    ogImageType
    ogImage
    ogKeyword
    ogDescription
    lectures
    quickBanner
    topBanner
    topLineBanner
    footerBanner
    footerLineBanner
    isFooterFix
    eventVideos {
      videoNo
      videoTypeCode
      videoURL
      videoTitle
      isClickPlay
      thumbnailType
      thumbnail
      __typename
    }
    eventBenefits {
      benefitNo
      benefitTypeCode
      benefitValue
      benefitTitle
      __typename
    }
    scheduleImage
    lectureLists
    freepassLists {
      categoryName
      __typename
    }
    isUseTimer
    isTimerFixed
    spreadImage
    eventMessageSchedule {
      eventKey
      templateId
      scheduleBeginDate
      scheduleEndDate
      scheduleTime
      __typename
    }
    eventCardinals {
      cardinalNumber
      cardinalBeginDate
      cardinalEndDate
      __typename
    }
    eventInstructions {
      InstructionNumber
      InstructionTitle
      InstructionContent
      __typename
    }
    eventAttributes {
      AttributeId
      AttributeName
      AttributeDescription
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventMutationVariables,
  APITypes.CreateEventMutation
>;
export const updateEvent = /* GraphQL */ `mutation UpdateEvent(
  $input: UpdateEventInput!
  $condition: ModelEventConditionInput
) {
  updateEvent(input: $input, condition: $condition) {
    id
    sitesID
    mainCategoryClassCode
    subCategoryClassCode
    eventCode
    eventName
    eventURL
    ogImageType
    ogImage
    ogKeyword
    ogDescription
    lectures
    quickBanner
    topBanner
    topLineBanner
    footerBanner
    footerLineBanner
    isFooterFix
    eventVideos {
      videoNo
      videoTypeCode
      videoURL
      videoTitle
      isClickPlay
      thumbnailType
      thumbnail
      __typename
    }
    eventBenefits {
      benefitNo
      benefitTypeCode
      benefitValue
      benefitTitle
      __typename
    }
    scheduleImage
    lectureLists
    freepassLists {
      categoryName
      __typename
    }
    isUseTimer
    isTimerFixed
    spreadImage
    eventMessageSchedule {
      eventKey
      templateId
      scheduleBeginDate
      scheduleEndDate
      scheduleTime
      __typename
    }
    eventCardinals {
      cardinalNumber
      cardinalBeginDate
      cardinalEndDate
      __typename
    }
    eventInstructions {
      InstructionNumber
      InstructionTitle
      InstructionContent
      __typename
    }
    eventAttributes {
      AttributeId
      AttributeName
      AttributeDescription
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventMutationVariables,
  APITypes.UpdateEventMutation
>;
export const deleteEvent = /* GraphQL */ `mutation DeleteEvent(
  $input: DeleteEventInput!
  $condition: ModelEventConditionInput
) {
  deleteEvent(input: $input, condition: $condition) {
    id
    sitesID
    mainCategoryClassCode
    subCategoryClassCode
    eventCode
    eventName
    eventURL
    ogImageType
    ogImage
    ogKeyword
    ogDescription
    lectures
    quickBanner
    topBanner
    topLineBanner
    footerBanner
    footerLineBanner
    isFooterFix
    eventVideos {
      videoNo
      videoTypeCode
      videoURL
      videoTitle
      isClickPlay
      thumbnailType
      thumbnail
      __typename
    }
    eventBenefits {
      benefitNo
      benefitTypeCode
      benefitValue
      benefitTitle
      __typename
    }
    scheduleImage
    lectureLists
    freepassLists {
      categoryName
      __typename
    }
    isUseTimer
    isTimerFixed
    spreadImage
    eventMessageSchedule {
      eventKey
      templateId
      scheduleBeginDate
      scheduleEndDate
      scheduleTime
      __typename
    }
    eventCardinals {
      cardinalNumber
      cardinalBeginDate
      cardinalEndDate
      __typename
    }
    eventInstructions {
      InstructionNumber
      InstructionTitle
      InstructionContent
      __typename
    }
    eventAttributes {
      AttributeId
      AttributeName
      AttributeDescription
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventMutationVariables,
  APITypes.DeleteEventMutation
>;
export const createEventLog = /* GraphQL */ `mutation CreateEventLog(
  $input: CreateEventLogInput!
  $condition: ModelEventLogConditionInput
) {
  createEventLog(input: $input, condition: $condition) {
    id
    sitesID
    eventCode
    memberIdx
    memberId
    userInfo
    key
    value
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventLogMutationVariables,
  APITypes.CreateEventLogMutation
>;
export const updateEventLog = /* GraphQL */ `mutation UpdateEventLog(
  $input: UpdateEventLogInput!
  $condition: ModelEventLogConditionInput
) {
  updateEventLog(input: $input, condition: $condition) {
    id
    sitesID
    eventCode
    memberIdx
    memberId
    userInfo
    key
    value
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventLogMutationVariables,
  APITypes.UpdateEventLogMutation
>;
export const deleteEventLog = /* GraphQL */ `mutation DeleteEventLog(
  $input: DeleteEventLogInput!
  $condition: ModelEventLogConditionInput
) {
  deleteEventLog(input: $input, condition: $condition) {
    id
    sitesID
    eventCode
    memberIdx
    memberId
    userInfo
    key
    value
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventLogMutationVariables,
  APITypes.DeleteEventLogMutation
>;
export const createPassersList = /* GraphQL */ `mutation CreatePassersList(
  $input: CreatePassersListInput!
  $condition: ModelPassersListConditionInput
) {
  createPassersList(input: $input, condition: $condition) {
    id
    sitesID
    passersCategory
    passersType
    companyName
    companyLogo
    passersName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePassersListMutationVariables,
  APITypes.CreatePassersListMutation
>;
export const updatePassersList = /* GraphQL */ `mutation UpdatePassersList(
  $input: UpdatePassersListInput!
  $condition: ModelPassersListConditionInput
) {
  updatePassersList(input: $input, condition: $condition) {
    id
    sitesID
    passersCategory
    passersType
    companyName
    companyLogo
    passersName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePassersListMutationVariables,
  APITypes.UpdatePassersListMutation
>;
export const deletePassersList = /* GraphQL */ `mutation DeletePassersList(
  $input: DeletePassersListInput!
  $condition: ModelPassersListConditionInput
) {
  deletePassersList(input: $input, condition: $condition) {
    id
    sitesID
    passersCategory
    passersType
    companyName
    companyLogo
    passersName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePassersListMutationVariables,
  APITypes.DeletePassersListMutation
>;
export const createTestTable = /* GraphQL */ `mutation CreateTestTable(
  $input: CreateTestTableInput!
  $condition: ModelTestTableConditionInput
) {
  createTestTable(input: $input, condition: $condition) {
    id
    NumberData
    StringData
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTestTableMutationVariables,
  APITypes.CreateTestTableMutation
>;
export const updateTestTable = /* GraphQL */ `mutation UpdateTestTable(
  $input: UpdateTestTableInput!
  $condition: ModelTestTableConditionInput
) {
  updateTestTable(input: $input, condition: $condition) {
    id
    NumberData
    StringData
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTestTableMutationVariables,
  APITypes.UpdateTestTableMutation
>;
export const deleteTestTable = /* GraphQL */ `mutation DeleteTestTable(
  $input: DeleteTestTableInput!
  $condition: ModelTestTableConditionInput
) {
  deleteTestTable(input: $input, condition: $condition) {
    id
    NumberData
    StringData
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTestTableMutationVariables,
  APITypes.DeleteTestTableMutation
>;
export const createDDaySchedule = /* GraphQL */ `mutation CreateDDaySchedule(
  $input: CreateDDayScheduleInput!
  $condition: ModelDDayScheduleConditionInput
) {
  createDDaySchedule(input: $input, condition: $condition) {
    id
    sitesID
    title
    description
    ddayTypeCode
    ddayDate
    ddayBeginDate
    ddayEndDate
    linkURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDDayScheduleMutationVariables,
  APITypes.CreateDDayScheduleMutation
>;
export const updateDDaySchedule = /* GraphQL */ `mutation UpdateDDaySchedule(
  $input: UpdateDDayScheduleInput!
  $condition: ModelDDayScheduleConditionInput
) {
  updateDDaySchedule(input: $input, condition: $condition) {
    id
    sitesID
    title
    description
    ddayTypeCode
    ddayDate
    ddayBeginDate
    ddayEndDate
    linkURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDDayScheduleMutationVariables,
  APITypes.UpdateDDayScheduleMutation
>;
export const deleteDDaySchedule = /* GraphQL */ `mutation DeleteDDaySchedule(
  $input: DeleteDDayScheduleInput!
  $condition: ModelDDayScheduleConditionInput
) {
  deleteDDaySchedule(input: $input, condition: $condition) {
    id
    sitesID
    title
    description
    ddayTypeCode
    ddayDate
    ddayBeginDate
    ddayEndDate
    linkURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDDayScheduleMutationVariables,
  APITypes.DeleteDDayScheduleMutation
>;
export const createFullServiceSubject = /* GraphQL */ `mutation CreateFullServiceSubject(
  $input: CreateFullServiceSubjectInput!
  $condition: ModelFullServiceSubjectConditionInput
) {
  createFullServiceSubject(input: $input, condition: $condition) {
    id
    sitesID
    subjectName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFullServiceSubjectMutationVariables,
  APITypes.CreateFullServiceSubjectMutation
>;
export const updateFullServiceSubject = /* GraphQL */ `mutation UpdateFullServiceSubject(
  $input: UpdateFullServiceSubjectInput!
  $condition: ModelFullServiceSubjectConditionInput
) {
  updateFullServiceSubject(input: $input, condition: $condition) {
    id
    sitesID
    subjectName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFullServiceSubjectMutationVariables,
  APITypes.UpdateFullServiceSubjectMutation
>;
export const deleteFullServiceSubject = /* GraphQL */ `mutation DeleteFullServiceSubject(
  $input: DeleteFullServiceSubjectInput!
  $condition: ModelFullServiceSubjectConditionInput
) {
  deleteFullServiceSubject(input: $input, condition: $condition) {
    id
    sitesID
    subjectName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFullServiceSubjectMutationVariables,
  APITypes.DeleteFullServiceSubjectMutation
>;
export const createFullServiceExam = /* GraphQL */ `mutation CreateFullServiceExam(
  $input: CreateFullServiceExamInput!
  $condition: ModelFullServiceExamConditionInput
) {
  createFullServiceExam(input: $input, condition: $condition) {
    id
    sitesID
    examName
    examDate
    examTime
    examDegree
    examFile
    FullServiceSubjectQuestions {
      nextToken
      startedAt
      __typename
    }
    FullServiceExplains {
      nextToken
      startedAt
      __typename
    }
    FullServiceUsers {
      nextToken
      startedAt
      __typename
    }
    FullServiceExamReviews {
      nextToken
      startedAt
      __typename
    }
    FullServiceSurveies {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFullServiceExamMutationVariables,
  APITypes.CreateFullServiceExamMutation
>;
export const updateFullServiceExam = /* GraphQL */ `mutation UpdateFullServiceExam(
  $input: UpdateFullServiceExamInput!
  $condition: ModelFullServiceExamConditionInput
) {
  updateFullServiceExam(input: $input, condition: $condition) {
    id
    sitesID
    examName
    examDate
    examTime
    examDegree
    examFile
    FullServiceSubjectQuestions {
      nextToken
      startedAt
      __typename
    }
    FullServiceExplains {
      nextToken
      startedAt
      __typename
    }
    FullServiceUsers {
      nextToken
      startedAt
      __typename
    }
    FullServiceExamReviews {
      nextToken
      startedAt
      __typename
    }
    FullServiceSurveies {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFullServiceExamMutationVariables,
  APITypes.UpdateFullServiceExamMutation
>;
export const deleteFullServiceExam = /* GraphQL */ `mutation DeleteFullServiceExam(
  $input: DeleteFullServiceExamInput!
  $condition: ModelFullServiceExamConditionInput
) {
  deleteFullServiceExam(input: $input, condition: $condition) {
    id
    sitesID
    examName
    examDate
    examTime
    examDegree
    examFile
    FullServiceSubjectQuestions {
      nextToken
      startedAt
      __typename
    }
    FullServiceExplains {
      nextToken
      startedAt
      __typename
    }
    FullServiceUsers {
      nextToken
      startedAt
      __typename
    }
    FullServiceExamReviews {
      nextToken
      startedAt
      __typename
    }
    FullServiceSurveies {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFullServiceExamMutationVariables,
  APITypes.DeleteFullServiceExamMutation
>;
export const createFullServiceSubjectQuestion = /* GraphQL */ `mutation CreateFullServiceSubjectQuestion(
  $input: CreateFullServiceSubjectQuestionInput!
  $condition: ModelFullServiceSubjectQuestionConditionInput
) {
  createFullServiceSubjectQuestion(input: $input, condition: $condition) {
    id
    fullServiceExamID
    answers {
      questionNumber
      answer
      score
      correctAnswerRate
      __typename
    }
    FullServiceSubject {
      id
      sitesID
      subjectName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fullServiceSubjectQuestionFullServiceSubjectId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFullServiceSubjectQuestionMutationVariables,
  APITypes.CreateFullServiceSubjectQuestionMutation
>;
export const updateFullServiceSubjectQuestion = /* GraphQL */ `mutation UpdateFullServiceSubjectQuestion(
  $input: UpdateFullServiceSubjectQuestionInput!
  $condition: ModelFullServiceSubjectQuestionConditionInput
) {
  updateFullServiceSubjectQuestion(input: $input, condition: $condition) {
    id
    fullServiceExamID
    answers {
      questionNumber
      answer
      score
      correctAnswerRate
      __typename
    }
    FullServiceSubject {
      id
      sitesID
      subjectName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fullServiceSubjectQuestionFullServiceSubjectId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFullServiceSubjectQuestionMutationVariables,
  APITypes.UpdateFullServiceSubjectQuestionMutation
>;
export const deleteFullServiceSubjectQuestion = /* GraphQL */ `mutation DeleteFullServiceSubjectQuestion(
  $input: DeleteFullServiceSubjectQuestionInput!
  $condition: ModelFullServiceSubjectQuestionConditionInput
) {
  deleteFullServiceSubjectQuestion(input: $input, condition: $condition) {
    id
    fullServiceExamID
    answers {
      questionNumber
      answer
      score
      correctAnswerRate
      __typename
    }
    FullServiceSubject {
      id
      sitesID
      subjectName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fullServiceSubjectQuestionFullServiceSubjectId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFullServiceSubjectQuestionMutationVariables,
  APITypes.DeleteFullServiceSubjectQuestionMutation
>;
export const createFullServiceExplain = /* GraphQL */ `mutation CreateFullServiceExplain(
  $input: CreateFullServiceExplainInput!
  $condition: ModelFullServiceExplainConditionInput
) {
  createFullServiceExplain(input: $input, condition: $condition) {
    id
    fullServiceExamID
    teacherName
    teacherImage
    subjectPC
    subjectMobile
    explainFile
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFullServiceExplainMutationVariables,
  APITypes.CreateFullServiceExplainMutation
>;
export const updateFullServiceExplain = /* GraphQL */ `mutation UpdateFullServiceExplain(
  $input: UpdateFullServiceExplainInput!
  $condition: ModelFullServiceExplainConditionInput
) {
  updateFullServiceExplain(input: $input, condition: $condition) {
    id
    fullServiceExamID
    teacherName
    teacherImage
    subjectPC
    subjectMobile
    explainFile
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFullServiceExplainMutationVariables,
  APITypes.UpdateFullServiceExplainMutation
>;
export const deleteFullServiceExplain = /* GraphQL */ `mutation DeleteFullServiceExplain(
  $input: DeleteFullServiceExplainInput!
  $condition: ModelFullServiceExplainConditionInput
) {
  deleteFullServiceExplain(input: $input, condition: $condition) {
    id
    fullServiceExamID
    teacherName
    teacherImage
    subjectPC
    subjectMobile
    explainFile
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFullServiceExplainMutationVariables,
  APITypes.DeleteFullServiceExplainMutation
>;
export const createFullServiceUser = /* GraphQL */ `mutation CreateFullServiceUser(
  $input: CreateFullServiceUserInput!
  $condition: ModelFullServiceUserConditionInput
) {
  createFullServiceUser(input: $input, condition: $condition) {
    id
    sitesID
    fullServiceExamID
    userIdx
    userId
    userName
    testNumber
    gradingType
    FullServiceUserAnswers {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFullServiceUserMutationVariables,
  APITypes.CreateFullServiceUserMutation
>;
export const updateFullServiceUser = /* GraphQL */ `mutation UpdateFullServiceUser(
  $input: UpdateFullServiceUserInput!
  $condition: ModelFullServiceUserConditionInput
) {
  updateFullServiceUser(input: $input, condition: $condition) {
    id
    sitesID
    fullServiceExamID
    userIdx
    userId
    userName
    testNumber
    gradingType
    FullServiceUserAnswers {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFullServiceUserMutationVariables,
  APITypes.UpdateFullServiceUserMutation
>;
export const deleteFullServiceUser = /* GraphQL */ `mutation DeleteFullServiceUser(
  $input: DeleteFullServiceUserInput!
  $condition: ModelFullServiceUserConditionInput
) {
  deleteFullServiceUser(input: $input, condition: $condition) {
    id
    sitesID
    fullServiceExamID
    userIdx
    userId
    userName
    testNumber
    gradingType
    FullServiceUserAnswers {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFullServiceUserMutationVariables,
  APITypes.DeleteFullServiceUserMutation
>;
export const createFullServiceUserAnswer = /* GraphQL */ `mutation CreateFullServiceUserAnswer(
  $input: CreateFullServiceUserAnswerInput!
  $condition: ModelFullServiceUserAnswerConditionInput
) {
  createFullServiceUserAnswer(input: $input, condition: $condition) {
    id
    fullServiceUserID
    FullServiceSubjectQuestion {
      id
      fullServiceExamID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fullServiceSubjectQuestionFullServiceSubjectId
      __typename
    }
    answers {
      questionNumber
      answer
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fullServiceUserAnswerFullServiceSubjectQuestionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFullServiceUserAnswerMutationVariables,
  APITypes.CreateFullServiceUserAnswerMutation
>;
export const updateFullServiceUserAnswer = /* GraphQL */ `mutation UpdateFullServiceUserAnswer(
  $input: UpdateFullServiceUserAnswerInput!
  $condition: ModelFullServiceUserAnswerConditionInput
) {
  updateFullServiceUserAnswer(input: $input, condition: $condition) {
    id
    fullServiceUserID
    FullServiceSubjectQuestion {
      id
      fullServiceExamID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fullServiceSubjectQuestionFullServiceSubjectId
      __typename
    }
    answers {
      questionNumber
      answer
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fullServiceUserAnswerFullServiceSubjectQuestionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFullServiceUserAnswerMutationVariables,
  APITypes.UpdateFullServiceUserAnswerMutation
>;
export const deleteFullServiceUserAnswer = /* GraphQL */ `mutation DeleteFullServiceUserAnswer(
  $input: DeleteFullServiceUserAnswerInput!
  $condition: ModelFullServiceUserAnswerConditionInput
) {
  deleteFullServiceUserAnswer(input: $input, condition: $condition) {
    id
    fullServiceUserID
    FullServiceSubjectQuestion {
      id
      fullServiceExamID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      fullServiceSubjectQuestionFullServiceSubjectId
      __typename
    }
    answers {
      questionNumber
      answer
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    fullServiceUserAnswerFullServiceSubjectQuestionId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFullServiceUserAnswerMutationVariables,
  APITypes.DeleteFullServiceUserAnswerMutation
>;
export const createFullServiceExamReview = /* GraphQL */ `mutation CreateFullServiceExamReview(
  $input: CreateFullServiceExamReviewInput!
  $condition: ModelFullServiceExamReviewConditionInput
) {
  createFullServiceExamReview(input: $input, condition: $condition) {
    id
    fullServiceExamID
    title
    content
    isDisplay
    isBest
    likeCount
    FullServiceExamReviewLikes {
      nextToken
      startedAt
      __typename
    }
    sortValue
    regMemberIdx
    regMemberId
    regMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFullServiceExamReviewMutationVariables,
  APITypes.CreateFullServiceExamReviewMutation
>;
export const updateFullServiceExamReview = /* GraphQL */ `mutation UpdateFullServiceExamReview(
  $input: UpdateFullServiceExamReviewInput!
  $condition: ModelFullServiceExamReviewConditionInput
) {
  updateFullServiceExamReview(input: $input, condition: $condition) {
    id
    fullServiceExamID
    title
    content
    isDisplay
    isBest
    likeCount
    FullServiceExamReviewLikes {
      nextToken
      startedAt
      __typename
    }
    sortValue
    regMemberIdx
    regMemberId
    regMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFullServiceExamReviewMutationVariables,
  APITypes.UpdateFullServiceExamReviewMutation
>;
export const deleteFullServiceExamReview = /* GraphQL */ `mutation DeleteFullServiceExamReview(
  $input: DeleteFullServiceExamReviewInput!
  $condition: ModelFullServiceExamReviewConditionInput
) {
  deleteFullServiceExamReview(input: $input, condition: $condition) {
    id
    fullServiceExamID
    title
    content
    isDisplay
    isBest
    likeCount
    FullServiceExamReviewLikes {
      nextToken
      startedAt
      __typename
    }
    sortValue
    regMemberIdx
    regMemberId
    regMemberName
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFullServiceExamReviewMutationVariables,
  APITypes.DeleteFullServiceExamReviewMutation
>;
export const createFullServiceExamReviewLike = /* GraphQL */ `mutation CreateFullServiceExamReviewLike(
  $input: CreateFullServiceExamReviewLikeInput!
  $condition: ModelFullServiceExamReviewLikeConditionInput
) {
  createFullServiceExamReviewLike(input: $input, condition: $condition) {
    id
    fullServiceExamReviewID
    regMemberIdx
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFullServiceExamReviewLikeMutationVariables,
  APITypes.CreateFullServiceExamReviewLikeMutation
>;
export const updateFullServiceExamReviewLike = /* GraphQL */ `mutation UpdateFullServiceExamReviewLike(
  $input: UpdateFullServiceExamReviewLikeInput!
  $condition: ModelFullServiceExamReviewLikeConditionInput
) {
  updateFullServiceExamReviewLike(input: $input, condition: $condition) {
    id
    fullServiceExamReviewID
    regMemberIdx
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFullServiceExamReviewLikeMutationVariables,
  APITypes.UpdateFullServiceExamReviewLikeMutation
>;
export const deleteFullServiceExamReviewLike = /* GraphQL */ `mutation DeleteFullServiceExamReviewLike(
  $input: DeleteFullServiceExamReviewLikeInput!
  $condition: ModelFullServiceExamReviewLikeConditionInput
) {
  deleteFullServiceExamReviewLike(input: $input, condition: $condition) {
    id
    fullServiceExamReviewID
    regMemberIdx
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFullServiceExamReviewLikeMutationVariables,
  APITypes.DeleteFullServiceExamReviewLikeMutation
>;
export const createFullServiceDisplayBoard = /* GraphQL */ `mutation CreateFullServiceDisplayBoard(
  $input: CreateFullServiceDisplayBoardInput!
  $condition: ModelFullServiceDisplayBoardConditionInput
) {
  createFullServiceDisplayBoard(input: $input, condition: $condition) {
    id
    sitesID
    displayBoardCode
    content
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFullServiceDisplayBoardMutationVariables,
  APITypes.CreateFullServiceDisplayBoardMutation
>;
export const updateFullServiceDisplayBoard = /* GraphQL */ `mutation UpdateFullServiceDisplayBoard(
  $input: UpdateFullServiceDisplayBoardInput!
  $condition: ModelFullServiceDisplayBoardConditionInput
) {
  updateFullServiceDisplayBoard(input: $input, condition: $condition) {
    id
    sitesID
    displayBoardCode
    content
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFullServiceDisplayBoardMutationVariables,
  APITypes.UpdateFullServiceDisplayBoardMutation
>;
export const deleteFullServiceDisplayBoard = /* GraphQL */ `mutation DeleteFullServiceDisplayBoard(
  $input: DeleteFullServiceDisplayBoardInput!
  $condition: ModelFullServiceDisplayBoardConditionInput
) {
  deleteFullServiceDisplayBoard(input: $input, condition: $condition) {
    id
    sitesID
    displayBoardCode
    content
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFullServiceDisplayBoardMutationVariables,
  APITypes.DeleteFullServiceDisplayBoardMutation
>;
export const createFullServiceSurvey = /* GraphQL */ `mutation CreateFullServiceSurvey(
  $input: CreateFullServiceSurveyInput!
  $condition: ModelFullServiceSurveyConditionInput
) {
  createFullServiceSurvey(input: $input, condition: $condition) {
    id
    fullServiceExamID
    userIdx
    userId
    userName
    testNumber
    survey
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFullServiceSurveyMutationVariables,
  APITypes.CreateFullServiceSurveyMutation
>;
export const updateFullServiceSurvey = /* GraphQL */ `mutation UpdateFullServiceSurvey(
  $input: UpdateFullServiceSurveyInput!
  $condition: ModelFullServiceSurveyConditionInput
) {
  updateFullServiceSurvey(input: $input, condition: $condition) {
    id
    fullServiceExamID
    userIdx
    userId
    userName
    testNumber
    survey
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFullServiceSurveyMutationVariables,
  APITypes.UpdateFullServiceSurveyMutation
>;
export const deleteFullServiceSurvey = /* GraphQL */ `mutation DeleteFullServiceSurvey(
  $input: DeleteFullServiceSurveyInput!
  $condition: ModelFullServiceSurveyConditionInput
) {
  deleteFullServiceSurvey(input: $input, condition: $condition) {
    id
    fullServiceExamID
    userIdx
    userId
    userName
    testNumber
    survey
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFullServiceSurveyMutationVariables,
  APITypes.DeleteFullServiceSurveyMutation
>;
export const createTextContents = /* GraphQL */ `mutation CreateTextContents(
  $input: CreateTextContentsInput!
  $condition: ModelTextContentsConditionInput
) {
  createTextContents(input: $input, condition: $condition) {
    id
    sitesID
    textContentsCode
    title
    description
    subDescription
    linkURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTextContentsMutationVariables,
  APITypes.CreateTextContentsMutation
>;
export const updateTextContents = /* GraphQL */ `mutation UpdateTextContents(
  $input: UpdateTextContentsInput!
  $condition: ModelTextContentsConditionInput
) {
  updateTextContents(input: $input, condition: $condition) {
    id
    sitesID
    textContentsCode
    title
    description
    subDescription
    linkURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTextContentsMutationVariables,
  APITypes.UpdateTextContentsMutation
>;
export const deleteTextContents = /* GraphQL */ `mutation DeleteTextContents(
  $input: DeleteTextContentsInput!
  $condition: ModelTextContentsConditionInput
) {
  deleteTextContents(input: $input, condition: $condition) {
    id
    sitesID
    textContentsCode
    title
    description
    subDescription
    linkURL
    isUse
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTextContentsMutationVariables,
  APITypes.DeleteTextContentsMutation
>;
export const createAdminLog = /* GraphQL */ `mutation CreateAdminLog(
  $input: CreateAdminLogInput!
  $condition: ModelAdminLogConditionInput
) {
  createAdminLog(input: $input, condition: $condition) {
    id
    siteCode
    key
    content
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminLogMutationVariables,
  APITypes.CreateAdminLogMutation
>;
export const updateAdminLog = /* GraphQL */ `mutation UpdateAdminLog(
  $input: UpdateAdminLogInput!
  $condition: ModelAdminLogConditionInput
) {
  updateAdminLog(input: $input, condition: $condition) {
    id
    siteCode
    key
    content
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminLogMutationVariables,
  APITypes.UpdateAdminLogMutation
>;
export const deleteAdminLog = /* GraphQL */ `mutation DeleteAdminLog(
  $input: DeleteAdminLogInput!
  $condition: ModelAdminLogConditionInput
) {
  deleteAdminLog(input: $input, condition: $condition) {
    id
    siteCode
    key
    content
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminLogMutationVariables,
  APITypes.DeleteAdminLogMutation
>;
export const createLiveSchedule = /* GraphQL */ `mutation CreateLiveSchedule(
  $input: CreateLiveScheduleInput!
  $condition: ModelLiveScheduleConditionInput
) {
  createLiveSchedule(input: $input, condition: $condition) {
    id
    sitesID
    liveDate
    liveBeginTime
    liveEndTime
    liveTypeCode
    title
    description
    teachers {
      teacherIdx
      teacherName
      __typename
    }
    liveURL
    isUseReplay
    isPause
    pauseId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLiveScheduleMutationVariables,
  APITypes.CreateLiveScheduleMutation
>;
export const updateLiveSchedule = /* GraphQL */ `mutation UpdateLiveSchedule(
  $input: UpdateLiveScheduleInput!
  $condition: ModelLiveScheduleConditionInput
) {
  updateLiveSchedule(input: $input, condition: $condition) {
    id
    sitesID
    liveDate
    liveBeginTime
    liveEndTime
    liveTypeCode
    title
    description
    teachers {
      teacherIdx
      teacherName
      __typename
    }
    liveURL
    isUseReplay
    isPause
    pauseId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLiveScheduleMutationVariables,
  APITypes.UpdateLiveScheduleMutation
>;
export const deleteLiveSchedule = /* GraphQL */ `mutation DeleteLiveSchedule(
  $input: DeleteLiveScheduleInput!
  $condition: ModelLiveScheduleConditionInput
) {
  deleteLiveSchedule(input: $input, condition: $condition) {
    id
    sitesID
    liveDate
    liveBeginTime
    liveEndTime
    liveTypeCode
    title
    description
    teachers {
      teacherIdx
      teacherName
      __typename
    }
    liveURL
    isUseReplay
    isPause
    pauseId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLiveScheduleMutationVariables,
  APITypes.DeleteLiveScheduleMutation
>;
export const createLiveReplay = /* GraphQL */ `mutation CreateLiveReplay(
  $input: CreateLiveReplayInput!
  $condition: ModelLiveReplayConditionInput
) {
  createLiveReplay(input: $input, condition: $condition) {
    id
    sitesID
    LiveReplay {
      id
      sitesID
      lectureCategoryCode
      replayTypeCode
      liveScheduleId
      lectureName
      replayURL
      thumbnail
      isUseReady
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReplayLiveReplayId
      __typename
    }
    lectureCategoryCode
    replayTypeCode
    liveScheduleId
    lectureName
    replayURL
    thumbnail
    teachers {
      teacherIdx
      teacherName
      __typename
    }
    isUseReady
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    liveReplayLiveReplayId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLiveReplayMutationVariables,
  APITypes.CreateLiveReplayMutation
>;
export const updateLiveReplay = /* GraphQL */ `mutation UpdateLiveReplay(
  $input: UpdateLiveReplayInput!
  $condition: ModelLiveReplayConditionInput
) {
  updateLiveReplay(input: $input, condition: $condition) {
    id
    sitesID
    LiveReplay {
      id
      sitesID
      lectureCategoryCode
      replayTypeCode
      liveScheduleId
      lectureName
      replayURL
      thumbnail
      isUseReady
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReplayLiveReplayId
      __typename
    }
    lectureCategoryCode
    replayTypeCode
    liveScheduleId
    lectureName
    replayURL
    thumbnail
    teachers {
      teacherIdx
      teacherName
      __typename
    }
    isUseReady
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    liveReplayLiveReplayId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLiveReplayMutationVariables,
  APITypes.UpdateLiveReplayMutation
>;
export const deleteLiveReplay = /* GraphQL */ `mutation DeleteLiveReplay(
  $input: DeleteLiveReplayInput!
  $condition: ModelLiveReplayConditionInput
) {
  deleteLiveReplay(input: $input, condition: $condition) {
    id
    sitesID
    LiveReplay {
      id
      sitesID
      lectureCategoryCode
      replayTypeCode
      liveScheduleId
      lectureName
      replayURL
      thumbnail
      isUseReady
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReplayLiveReplayId
      __typename
    }
    lectureCategoryCode
    replayTypeCode
    liveScheduleId
    lectureName
    replayURL
    thumbnail
    teachers {
      teacherIdx
      teacherName
      __typename
    }
    isUseReady
    sortValue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    liveReplayLiveReplayId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLiveReplayMutationVariables,
  APITypes.DeleteLiveReplayMutation
>;
export const createLiveReservation = /* GraphQL */ `mutation CreateLiveReservation(
  $input: CreateLiveReservationInput!
  $condition: ModelLiveReservationConditionInput
) {
  createLiveReservation(input: $input, condition: $condition) {
    id
    sitesID
    LiveReplay {
      id
      sitesID
      lectureCategoryCode
      replayTypeCode
      liveScheduleId
      lectureName
      replayURL
      thumbnail
      isUseReady
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReplayLiveReplayId
      __typename
    }
    LiveSchedule {
      id
      sitesID
      liveDate
      liveBeginTime
      liveEndTime
      liveTypeCode
      title
      description
      liveURL
      isUseReplay
      isPause
      pauseId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    userIdx
    userId
    userName
    userTel
    userEmail
    userInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    liveReservationLiveReplayId
    liveReservationLiveScheduleId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLiveReservationMutationVariables,
  APITypes.CreateLiveReservationMutation
>;
export const updateLiveReservation = /* GraphQL */ `mutation UpdateLiveReservation(
  $input: UpdateLiveReservationInput!
  $condition: ModelLiveReservationConditionInput
) {
  updateLiveReservation(input: $input, condition: $condition) {
    id
    sitesID
    LiveReplay {
      id
      sitesID
      lectureCategoryCode
      replayTypeCode
      liveScheduleId
      lectureName
      replayURL
      thumbnail
      isUseReady
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReplayLiveReplayId
      __typename
    }
    LiveSchedule {
      id
      sitesID
      liveDate
      liveBeginTime
      liveEndTime
      liveTypeCode
      title
      description
      liveURL
      isUseReplay
      isPause
      pauseId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    userIdx
    userId
    userName
    userTel
    userEmail
    userInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    liveReservationLiveReplayId
    liveReservationLiveScheduleId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLiveReservationMutationVariables,
  APITypes.UpdateLiveReservationMutation
>;
export const deleteLiveReservation = /* GraphQL */ `mutation DeleteLiveReservation(
  $input: DeleteLiveReservationInput!
  $condition: ModelLiveReservationConditionInput
) {
  deleteLiveReservation(input: $input, condition: $condition) {
    id
    sitesID
    LiveReplay {
      id
      sitesID
      lectureCategoryCode
      replayTypeCode
      liveScheduleId
      lectureName
      replayURL
      thumbnail
      isUseReady
      sortValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      liveReplayLiveReplayId
      __typename
    }
    LiveSchedule {
      id
      sitesID
      liveDate
      liveBeginTime
      liveEndTime
      liveTypeCode
      title
      description
      liveURL
      isUseReplay
      isPause
      pauseId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    userIdx
    userId
    userName
    userTel
    userEmail
    userInfo
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    liveReservationLiveReplayId
    liveReservationLiveScheduleId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLiveReservationMutationVariables,
  APITypes.DeleteLiveReservationMutation
>;
export const createLiveApplication = /* GraphQL */ `mutation CreateLiveApplication(
  $input: CreateLiveApplicationInput!
  $condition: ModelLiveApplicationConditionInput
) {
  createLiveApplication(input: $input, condition: $condition) {
    id
    sitesID
    userIdx
    userId
    userName
    content
    isDisplay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLiveApplicationMutationVariables,
  APITypes.CreateLiveApplicationMutation
>;
export const updateLiveApplication = /* GraphQL */ `mutation UpdateLiveApplication(
  $input: UpdateLiveApplicationInput!
  $condition: ModelLiveApplicationConditionInput
) {
  updateLiveApplication(input: $input, condition: $condition) {
    id
    sitesID
    userIdx
    userId
    userName
    content
    isDisplay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLiveApplicationMutationVariables,
  APITypes.UpdateLiveApplicationMutation
>;
export const deleteLiveApplication = /* GraphQL */ `mutation DeleteLiveApplication(
  $input: DeleteLiveApplicationInput!
  $condition: ModelLiveApplicationConditionInput
) {
  deleteLiveApplication(input: $input, condition: $condition) {
    id
    sitesID
    userIdx
    userId
    userName
    content
    isDisplay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLiveApplicationMutationVariables,
  APITypes.DeleteLiveApplicationMutation
>;
export const createEventCardinalBenefit = /* GraphQL */ `mutation CreateEventCardinalBenefit(
  $input: CreateEventCardinalBenefitInput!
  $condition: ModelEventCardinalBenefitConditionInput
) {
  createEventCardinalBenefit(input: $input, condition: $condition) {
    id
    eventId
    eventCode
    siteCode
    benefitMemberCount
    benefitCycle
    Lastbenefit
    benefitItems {
      benefitType
      benefitCode
      benefitName
      __typename
    }
    benefitCardinalResults {
      cardinalNumber
      cardinalBeginDate
      cardinalEndDate
      benefitMembers
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEventCardinalBenefitMutationVariables,
  APITypes.CreateEventCardinalBenefitMutation
>;
export const updateEventCardinalBenefit = /* GraphQL */ `mutation UpdateEventCardinalBenefit(
  $input: UpdateEventCardinalBenefitInput!
  $condition: ModelEventCardinalBenefitConditionInput
) {
  updateEventCardinalBenefit(input: $input, condition: $condition) {
    id
    eventId
    eventCode
    siteCode
    benefitMemberCount
    benefitCycle
    Lastbenefit
    benefitItems {
      benefitType
      benefitCode
      benefitName
      __typename
    }
    benefitCardinalResults {
      cardinalNumber
      cardinalBeginDate
      cardinalEndDate
      benefitMembers
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEventCardinalBenefitMutationVariables,
  APITypes.UpdateEventCardinalBenefitMutation
>;
export const deleteEventCardinalBenefit = /* GraphQL */ `mutation DeleteEventCardinalBenefit(
  $input: DeleteEventCardinalBenefitInput!
  $condition: ModelEventCardinalBenefitConditionInput
) {
  deleteEventCardinalBenefit(input: $input, condition: $condition) {
    id
    eventId
    eventCode
    siteCode
    benefitMemberCount
    benefitCycle
    Lastbenefit
    benefitItems {
      benefitType
      benefitCode
      benefitName
      __typename
    }
    benefitCardinalResults {
      cardinalNumber
      cardinalBeginDate
      cardinalEndDate
      benefitMembers
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEventCardinalBenefitMutationVariables,
  APITypes.DeleteEventCardinalBenefitMutation
>;
